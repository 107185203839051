import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import './Sidebar.scss';
import logo from '../../assets/logo.svg';
import axios from 'axios';
import { config } from '../../Constants';

function Sidebar({ navItems }) {
  /* Variables */
  const location = useLocation();
  const { setIsLogged, isSideBarOpen } = useContext(AuthContext);

  /* Functions */
  const logout = async () => {
    try {
      const { data } = await axios.get(
        `${config.SERVER_IP}/logout/userLogout`,
        {}, // This is the request body
        {
          withCredentials: true,
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
          },
        }
      );
      if (data.success) {
        setIsLogged(false);
      } else {
        console.log(data.error);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <nav className={`sidebar ${isSideBarOpen ? 'open' : ''}`}>
      <div className='logo'>
        <img src={logo} alt='' />
      </div>

      <ul className='side-menu'>
        {navItems.map((item, index) => {
          return (
            <li key={index}>
              <Link
                to={item.path}
                className={item.path === location.pathname ? 'active' : ''}
              >
                <img src={item.icon} alt='' />
                <span>{item.name}</span>
              </Link>
            </li>
          );
        })}

        <li>
          <p onClick={logout}>התנתקות</p>
        </li>
      </ul>
    </nav>
  );
}

export default Sidebar;
