import React, { useEffect, useState, useRef } from 'react';
import arrowDown from '../../assets/icons/arrow-down.svg';
import './MyDrop.scss';

export default function MyDrop(props) {
  const {
    colorizeSwitchOptions = null,
    optionsArr,
    initValueIndex,
    handler = null,
    attributeToPrint = null,
    toFirst = null,
    setToFirst,
  } = props;

  /* Variables */
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [currentValueIndex, setCurrentValueIndex] = useState(initValueIndex);

  /* Functions */

  const handlerClick = () => {
    setIsOpen((prev) => {
      return !prev;
    });
  };

  const handlerSelect = (index) => {
    setToFirst(false);
    setCurrentValueIndex(index);

    if (handler) {
      handler(index);
    }
  };

  useEffect(() => {
    if (toFirst) {
      setCurrentValueIndex(0);
    }
  }, [toFirst]);

  useEffect(() => {
    function handleClickOutside(event) {
      // Check if the clicked element is within the dropdown or is a scrollbar
      if (
        !wrapperRef.current.contains(event.target) &&
        !event.target.classList.contains('option') &&
        !event.target.classList.contains('dropdown')
      ) {
        setIsOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (optionsArr) {
      setOptions(optionsArr);
    }
  }, [optionsArr]);

  useEffect(() => {
    setCurrentValueIndex(initValueIndex);
  }, [initValueIndex]);

  return (
    <div
      className={`MyDrop ${isOpen ? 'open' : ''} ${
        colorizeSwitchOptions ? 'colorized' : ''
      } ${props.className}`}
      onClick={handlerClick}
    >
      <div ref={wrapperRef} className={`current color-${currentValueIndex}`}>
        {options.length > 0 &&
          (attributeToPrint ? (
            <span>{options[currentValueIndex][attributeToPrint]}</span>
          ) : (
            <span>{options[currentValueIndex]}</span>
          ))}

        <img src={arrowDown} alt='arrow' />
      </div>

      <div className='dropdown'>
        {options.length > 0 &&
          options.map(function (item, i) {
            return (
              <div
                key={i}
                className={`option color-${i}`}
                onClick={() => {
                  handlerSelect(i);
                }}
              >
                {attributeToPrint ? item[attributeToPrint] : item}
              </div>
            );
          })}
      </div>
    </div>
  );
}
