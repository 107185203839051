import './Agent.scss';
import TopLine from '../../components/TopLine/TopLine';
import { ExcelRenderer } from 'react-excel-renderer';
import excel from '../../assets/icons/excel.svg';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TableAgent from '../../components/TableAgent/TableAgent';
import MyDrop from '../../components/MyDrop/MyDrop';
import writeXlsxFile from 'write-excel-file';
import 'react-dropdown/style.css';
import { config } from '../../Constants';
// import AbortController from 'abort-controller';

export default function Agent({ data }) {
  /* Local variables */
  const inputFileRef = React.useRef();
  const [rows, setRows] = useState([]);
  const [searchRows, setSearchRows] = useState([]);
  const [loadingFile, setLoadingFile] = useState(false);

  const [deviation, setDeviation] = useState(0);

  const [agent, setAgent] = useState(null);
  const [agentsOptions, setAgentsOptions] = useState([
    { id_agents: '0', fullName: 'כל הסוכנים', id_set: '0' },
  ]);

  const [year, setYear] = useState('');
  const [yearOptions, setYearOptions] = useState(['הכל']);

  const [agentAgreements, setAgentAgreements] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [resertAgentDropDown, setResetAgentDropDown] = useState(false);

  /* Functions */
  const excelHandler = (event) => {
    setLoadingFile(true);
    let fileObj = event.target.files[0];

    // pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.error(err);
        setLoadingFile(false);
      } else {
        let controller = new AbortController();
        insertAgentFile(resp.rows, controller);
      }
    });
  };

  const insertAgentFile = async (rows, controller) => {
    try {
      const { data } = await axios.post(
        `${config.SERVER_IP}/agent/insertAgentFile`,
        { rows: rows },
        {
          // signal: controller.signal,
          withCredentials: true,
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
          },
        }
      );

      if (data === 'success') {
        if (agent && agent.id_agents && year) {
          getAgentTable(year, page);
        } else {
          let controller = new AbortController();
          getAgents(controller);
        }
      }
    } catch (err) {
      console.error(err);
    }

    setLoadingFile(false);
  };

  const getAgentTable = async (yearParam, pageParam = 1, searchText = '') => {
    if (agent?.id_agents && year) {
      const paramsObj = {
        id_agent: agent.id_agents,
        year: year,
        page: pageParam,
        searchText: searchText,
      };

      if (yearParam) {
        paramsObj.year = yearParam;
      }

      try {
        const { data } = await axios.post(
          `${config.SERVER_IP}/agent/getAgentTable`,
          {},
          {
            withCredentials: true,
            mode: 'cors',
            credentials: 'include',
            headers: {
              'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
            },
            params: paramsObj,
          }
        );

        if (pageParam === 1) {
          setRows(data.rowsData);
          setSearchRows(data.rowsData);
        } else {
          setRows((prevRows) => [...prevRows, ...data.rowsData]);
          setSearchRows((prevRows) => [...prevRows, ...data.rowsData]);
        }

        if (data.rowsData.length === 0) {
          setHasMore(false);
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  const loadMore = async () => {
    if (hasMore) {
      setPage((prevPage) => prevPage + 1);
      try {
        await getAgentTable(year, page + 1);
      } catch (error) {
        console.error('Error loading more rows:', error);
      }
    }
  };

  const searchInput = (e) => {
    setResetAgentDropDown(true);
    if (e.target.value === '') {
      setAgent(agentsOptions[0]);
      getAgentTable(year, page);
    } else {
      const searchText = e.target.value;
      getAgentTable(year, page, searchText);
      setSearchRows(
        rows.filter(
          (cell) =>
            cell.fullName.includes(e.target.value) ||
            cell.company.includes(e.target.value) ||
            cell.insuranceTopic.includes(e.target.value) ||
            cell.insuranceSubtopic.includes(e.target.value)
        )
      );
    }
  };

  const agentHandler = (index) => {
    setAgent(agentsOptions[index]);
  };

  const getAgents = async (controller) => {
    try {
      const { data } = await axios.post(
        `${config.SERVER_IP}/agent/getAgentsWithSets`,
        {},
        {
          withCredentials: true,
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
          },
        }
      );

      if (data.rowsData.length) {
        const arrayAgents = [
          { id_agents: '0', fullName: 'כל הסוכנים', id_set: '0' },
        ];

        for (let row of data.rowsData) {
          arrayAgents.push(row);
        }

        setAgent(arrayAgents[0]);
        setAgentsOptions(arrayAgents);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getAgentYears = async (controller) => {
    if (agent.id_agents) {
      try {
        const { data } = await axios.post(
          `${config.SERVER_IP}/agent/getAgentYears`,
          {},
          {
            signal: controller.signal,
            withCredentials: true,
            mode: 'cors',
            credentials: 'include',
            headers: {
              'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
            },
            params: { id_agent: agent.id_agents },
          }
        );

        if (data.rowsData) {
          const arrayYears = ['הכל'];

          for (let row of data.rowsData) {
            arrayYears.push(row.year);
          }

          setYearOptions(arrayYears);

          if (year === arrayYears[0]) {
            getAgentTable(year, page);
          } else {
            setYear(arrayYears[0]);
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  const yearHandler = (index) => {
    setYear(yearOptions[index]);
  };

  const exportHandler = async () => {
    const tableRows = document.querySelectorAll('.agent-table .row');
    const arrayToExport = [];

    tableRows.forEach((row) => {
      const rowCells = row.querySelectorAll('.cell');
      const rowArray = [];

      rowCells.forEach((cell) => {
        rowArray.push({
          value: cell.innerText,
          width: 13,
          height: 25,
          align: 'center',
          fontWeight:
            row.classList.contains('head') || row.classList.contains('sum')
              ? 'bold'
              : '400',
        });
      });

      arrayToExport.push(rowArray);
    });

    const columns = [
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
    ];

    await writeXlsxFile(arrayToExport, {
      columns: columns,
      fileName: 'טבלת סיכום סוכנים.xlsx',
    });
  };

  const getAgentAgreements = async () => {
    if (agent && agent.id_agents) {
      try {
        const { data } = await axios.post(
          `${config.SERVER_IP}/agent/getAgentAgreements`,
          {},
          {
            withCredentials: true,
            mode: 'cors',
            credentials: 'include',
            headers: {
              'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
            },
            params: { id_agent: agent.id_agents },
          }
        );

        setAgentAgreements(data.rowsData);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getDeviation = async () => {
    try {
      const result = await axios.post(
        `${config.SERVER_IP}/settings/getDeviation`,
        {},
        {
          withCredentials: true,
        }
      );
      if (result.data && result.data.rows) {
        const deviationValue = +result.data.rows[0].value;
        setDeviation(deviationValue);
      } else {
        let controller = new AbortController();
        getAgents(controller);
      }
    } catch (err) {
      console.log(err);
    }
  };

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      // Call the search function here
      // For example:
      searchInput(event);
    }
  }

  /* Triggers */

  useEffect(() => {
    getDeviation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let controller = new AbortController();
    getAgents(controller);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviation]);

  useEffect(() => {
    let controller = new AbortController();
    setRows([]);
    setSearchRows([]);

    if (agent) {
      getAgentYears(controller);
    }

    return () => {
      if (controller) {
        controller.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agent]);

  useEffect(() => {
    if (year) {
      getAgentTable(year, page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, page]);

  useEffect(() => {
    if (yearOptions.length > 0) {
      getAgentAgreements();
      getAgentTable(year, page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearOptions.length]);

  return (
    <div className='page agent'>
      <TopLine title={data.name} />

      <div className='sub-top-line'>
        <button
          className={`load-file ${loadingFile ? 'loading' : ''}`}
          onClick={() => {
            inputFileRef.current.click();
          }}
        >
          <img src={excel} alt='excel' />
          <span>{`${loadingFile ? 'טוען...' : 'הזנת קובץ אקסל'}`}</span>
          <input
            type='file'
            onChange={excelHandler}
            style={{ padding: '10px', display: 'none' }}
            ref={inputFileRef}
          />
        </button>

        <button className='export-file' onClick={exportHandler}>
          <img src={excel} alt='excel' />
          <span>יצוא טבלת אקסל</span>
        </button>
      </div>

      <div className='sub-top-line'>
        <MyDrop
          className='agent'
          optionsArr={agentsOptions}
          initValueIndex={0}
          handler={agentHandler}
          attributeToPrint='fullName'
          toFirst={resertAgentDropDown}
          setToFirst={setResetAgentDropDown}
        />

        <MyDrop
          className='year'
          optionsArr={yearOptions}
          initValueIndex={0}
          handler={yearHandler}
          setToFirst={setResetAgentDropDown}
        />

        <input
          className='search'
          type='text'
          placeholder='חיפוש'
          onInput={searchInput}
          onKeyDown={handleKeyDown}
        />
      </div>

      <div className='content'>
        {agentAgreements && (
          <TableAgent
            rows={searchRows}
            agentAgreementsProp={agentAgreements}
            deviation={deviation}
            loadMore={loadMore}
          />
        )}
      </div>
    </div>
  );
}
