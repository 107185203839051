import './AgentRow.scss';
import React from 'react';
import { numberWithCommas } from '../../pages/utils/helpers';

export default function AgentRow({
  arrayShown,
  groupRowsIdentifier,
  indexRow,
  row,
}) {
  /* Variables */

  /* Functions */

  return (
    <>
      {
        <div
          className={`row agent-row ${
            arrayShown.includes(groupRowsIdentifier) ? '' : 'hide'
          }`}
          key={indexRow}
          data-identify={groupRowsIdentifier}
        >
          {Object.values(row)
            .slice(1, -2)
            .map((cell, indexCell) => {
              if (typeof cell === 'string') {
                return (
                  <div className='cell' key={indexCell}>
                    {numberWithCommas(cell, false)}
                  </div>
                );
              } else {
                return (
                  <div className='cell' key={indexCell}>
                    {numberWithCommas(cell, indexCell !== 1)}
                  </div>
                );
              }
            })}
        </div>
      }
    </>
  );
}
