import React from 'react';
import {format_date} from '../../pages/utils/helpers';
import './TableAgreements.scss';

function TableAgreements({rows, handlerRowClick}) {
	return (
		<div className="table agreements-table">
			<div className="row head" key={'head'}>
				<div className="cell">שם הסכם</div>
				<div className="cell">חברה</div>
				<div className="cell">תחום</div>
				<div className="cell">סוג</div>
				<div className="cell">תלוי ביעד אחר</div>
				<div className="cell">סטאטוס</div>
				<div className="cell">החל מ</div>
				<div className="cell">עד</div>
			</div>

			{rows &&
				rows.map((row, indexRow) => (
					<div
						className="row"
						key={indexRow}
						onClick={() => {
							handlerRowClick(row.id_agreement);
						}}
					>
						<div className="cell">{row.name}</div>
						<div className="cell">{row.company}</div>
						<div className="cell">{row.topic}</div>
						<div className="cell">{row.subtopic}</div>
						<div className="cell">לא תלוי</div>
						<div className={`cell ${parseInt(row.filled) === 1 ? 'filled' : 'not-filled'}`}>{`${
							parseInt(row.filled) === 1 ? 'מוזן' : 'לא מוזן'
						}`}</div>
						<div className="cell">{format_date(row.start)}</div>
						<div className="cell">{format_date(row.end)}</div>
					</div>
				))}
		</div>
	);
}

export default TableAgreements;
