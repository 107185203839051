import React, { useEffect, useRef, useState } from 'react';
import './Settings.scss';
import axios from 'axios';
import TopLine from '../../components/TopLine/TopLine';
import 'react-dropdown/style.css';
import x from '../../assets/icons/x.svg';
import { config } from '../../Constants';

export default function Settings({ data }) {
  /* Variables */
  const [companies, setCompanies] = useState([]);
  const [topics, setTopics] = useState([]);
  const [subtopics, setSubtopics] = useState([]);
  const inputDeviation = useRef();
  const inputCompanyRef = useRef();
  const inputTopicRef = useRef();
  const inputSubtopicRef = useRef();
  const inputCurrentPasswordRef = useRef();
  const inputNewPasswordRef = useRef();
  const inputPasswordAgainRef = useRef();
  const [error, setError] = useState('');

  /* Functions */

  const handlerSaveDeviation = async () => {
    if (inputDeviation.current && inputDeviation.current.value) {
      const deviationValue = inputDeviation.current.value;

      try {
        const result = await axios.post(
          `${config.SERVER_IP}/settings/setDeviation`,
          {},
          {
            withCredentials: true,
            mode: 'cors',
            credentials: 'include',
            headers: {
              'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
            },
            params: { deviation: deviationValue },
          }
        );
        if (result.data && result.data.rows) {
          inputDeviation.current.value = result.data.rows[0].value;
          window.location.reload(false);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handlerChangePassword = async () => {
    if (
      inputCurrentPasswordRef.current &&
      inputNewPasswordRef.current &&
      inputPasswordAgainRef.current
    ) {
      const currentPassword = inputCurrentPasswordRef.current.value;
      const newPassword = inputNewPasswordRef.current.value;
      const passwordAgain = inputPasswordAgainRef.current.value;

      if (!currentPassword || !newPassword || !passwordAgain) {
        setError('לא מילאת את אחד השדות');
      } else if (newPassword !== passwordAgain) {
        setError('הסיסמאות אינן תואמות');
      } else {
        setError('');

        try {
          const result = await axios.post(
            `${config.SERVER_IP}/settings/changePassword`,
            {},
            {
              withCredentials: true,
              mode: 'cors',
              credentials: 'include',
              headers: {
                'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
              },
              params: {
                currentPassword: currentPassword,
                newPassword: newPassword,
              },
            }
          );
          if (result.data === 'wrong') {
            setError('הסיסמה הנוכחית אינה נכונה');
          } else if (result.data === 'success') {
            setError('הסיסמה שונתה בהצלחה');
          } else {
            setError('אירעה תקלה');
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  const getDeviation = async () => {
    try {
      const result = await axios.post(
        `${config.SERVER_IP}/settings/getDeviation`,
        {},
        {
          withCredentials: true,
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
          },
          params: {},
        }
      );
      if (result.data && result.data.rows) {
        inputDeviation.current.value = result.data.rows[0].value;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlerAddCompany = async () => {
    const company = inputCompanyRef.current.value;

    if (company) {
      try {
        const { data } = await axios.post(
          `${config.SERVER_IP}/settings/insertCompany`,
          {},
          {
            withCredentials: true,
            mode: 'cors',
            credentials: 'include',
            headers: {
              'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
            },
            params: { company: company },
          }
        );

        if (data.result && data.message === 'success') {
          setCompanies((prev) => {
            return [...prev, data.result[0]];
          });
        } else if (data.result && data.message === 'already exists') {
          console.log(data);
          alert('company already exists');
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handlerAddTopic = async () => {
    const topic = inputTopicRef.current.value;
    if (topic) {
      try {
        const { data } = await axios.post(
          `${config.SERVER_IP}/settings/insertTopic`,
          {},
          {
            withCredentials: true,
            mode: 'cors',
            credentials: 'include',
            headers: {
              'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
            },
            params: { topic: topic },
          }
        );
        if (data.result && data.message === 'success') {
          setTopics((prev) => {
            return [...prev, data.result[0]];
          });
        } else if (data.result && data.message === 'already exists') {
          alert('topic already exists');
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handlerAddSubtopic = async () => {
    const subtopic = inputSubtopicRef.current.value;

    if (subtopic) {
      try {
        const { data } = await axios.post(
          `${config.SERVER_IP}/settings/insertSubtopic`,
          {},
          {
            withCredentials: true,
            mode: 'cors',
            credentials: 'include',
            headers: {
              'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
            },
            params: { subtopic: subtopic },
          }
        );

        if (data.result && data.message === 'success') {
          setSubtopics((prev) => {
            return [...prev, data.result[0]];
          });
        } else if (data.result && data.message === 'already exists') {
          alert('subtopic already exists');
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getCompaniesOptions = async () => {
    try {
      const { data } = await axios.post(
        `${config.SERVER_IP}/settings/getCompanies`,
        {},
        {
          withCredentials: true,
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
          },
          params: {},
        }
      );
      if (data && data.rowsData) {
        const arr = [];

        data.rowsData.forEach((item) => {
          arr.push(item);
        });

        setCompanies(arr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getTopicsOptions = async () => {
    try {
      const { data } = await axios.post(
        `${config.SERVER_IP}/settings/getTopics`,
        {},
        {
          withCredentials: true,
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
          },
          params: {},
        }
      );

      if (data && data.rowsData) {
        const arr = [];

        data.rowsData.forEach((item) => {
          arr.push(item);
        });

        setTopics(arr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSubtopicsOptions = async () => {
    try {
      const { data } = await axios.post(
        `${config.SERVER_IP}/settings/getSubtopics`,
        {},
        {
          withCredentials: true,
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
          },
          params: {},
        }
      );

      if (data && data.rowsData) {
        const arr = [];

        data.rowsData.forEach((item, index) => {
          arr.push(item);
        });

        setSubtopics(arr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteFromSetting = async (columnName, id) => {
    try {
      const { data } = await axios.post(
        `${config.SERVER_IP}/settings/${columnName}`,
        {},
        {
          withCredentials: true,
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
          },
          params: { item_id: id },
        }
      );
      window.location.reload(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDeviation();
    getCompaniesOptions();
    getTopicsOptions();
    getSubtopicsOptions();
  }, []);

  return (
    <div className='page settings'>
      <TopLine title={data.name} />

      <p className='instructions'>
        כאן ניתן לקבוע את טווח הסטיה המקסימלי, המשמש אינדיקציה להשוואות בעמוד
        "סוכן".
        <br />
        אם למשל נבחר טווח של 100, יסומנו באדום כל המקומות בהם הפער בין המספרים
        שהמערכת חישבה לבין מה שהוזן לה בקבצי האקסל עולה על 100.
      </p>

      <div className='wrapper-input'>
        <input
          ref={inputDeviation}
          type='number'
          min='0'
          placeholder='טווח סטיה'
          id='deviation-range'
        />

        <button onClick={handlerSaveDeviation}>שמירה</button>
      </div>

      <p className='instructions'>
        הרשימות שלהלן משמשות להצגת אפשרויות בחירה בטפסים הרלוונטיים.
        <br />
        כלומר בכל מקום שיש שדה לבחירת חברה (כגון בטופס יצירת הסכם), האפשרויות
        נלקחות מכאן.
      </p>

      <div className='content'>
        <div className='col'>
          <h3>חברות</h3>

          <div className='wrapper-add'>
            <input
              type='text'
              id='company'
              ref={inputCompanyRef}
              placeholder='הוספת חברה'
            />

            <button onClick={handlerAddCompany}>הוספה</button>
          </div>

          <ul>
            {companies.map((item, index) => (
              <li key={index}>
                <img
                  src={x}
                  data-id={item.id_company}
                  alt='remove'
                  onClick={() =>
                    deleteFromSetting('deletCompanies', item.id_company)
                  }
                />
                <span>{item.name}</span>
              </li>
            ))}
          </ul>
        </div>

        <div className='col'>
          <h3>תחומים</h3>

          <div className='wrapper-add'>
            <input
              type='text'
              id='topic'
              ref={inputTopicRef}
              placeholder='הוספת תחום'
            />

            <button onClick={handlerAddTopic}>הוספה</button>
          </div>

          <ul>
            {topics.map((item, index) => (
              <li key={index}>
                <img
                  src={x}
                  data-id={item.id_topic}
                  alt='remove'
                  onClick={() =>
                    deleteFromSetting('deletTopics', item.id_topic)
                  }
                />
                <span>{item.name}</span>
              </li>
            ))}
          </ul>
        </div>

        <div className='col'>
          <h3>תתי תחומים</h3>

          <div className='wrapper-add'>
            <input
              type='text'
              id='subtopic'
              ref={inputSubtopicRef}
              placeholder='הוספת תת תחום'
            />

            <button onClick={handlerAddSubtopic}>הוספה</button>
          </div>

          <ul>
            {subtopics.map((item, index) => (
              <li key={index}>
                <img
                  src={x}
                  data-id={item.id_subtopic}
                  alt='remove'
                  onClick={() =>
                    deleteFromSetting('deletSubtopics', item.id_subtopic)
                  }
                />
                <span>{item.name}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <p className='instructions'>שינוי סיסמה</p>

      <div className='wrapper-change-pas'>
        <input
          type='current-password'
          placeholder='סיסמה נוכחית'
          ref={inputCurrentPasswordRef}
        />
        <input
          type='new-password'
          placeholder='סיסמה חדשה'
          ref={inputNewPasswordRef}
        />
        <input
          type='password-again'
          placeholder='סיסמה בשנית'
          ref={inputPasswordAgainRef}
        />

        <button onClick={handlerChangePassword}>שמירה</button>

        <p className='error-message'>{error}</p>
      </div>
    </div>
  );
}
