const rgx = /^[0-9]*\.?[0-9]*$/;

export const numberWithCommas = (x, flagConvert = true) => {
  if (x) {
    if (flagConvert && x.toString().match(rgx)) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return x;
  }
};

export const format_date = (x) => {
  const d = new Date(x);

  let dt = d.getDate();
  if (dt < 10) {
    dt = '0' + dt;
  }

  let mn = d.getMonth() + 1;
  if (mn < 10) {
    mn = '0' + mn;
  }

  const yy = d.getFullYear();

  return dt + '-' + mn + '-' + yy;
};

export const getAgreementTypeName = (type) => {
  switch (parseInt(type)) {
    case 0:
      return 'סוכן - חברה';

    case 1:
      return 'סוכן - תלפיות';

    case 2:
      return 'תלפיות - חברה';

    default:
      return '';
  }
};
