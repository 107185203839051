const dev = {
  SERVER_IP: 'http://localhost:3009',
  ALLOW_ORIGIN: 'http://localhost:3009'
};

const prod = {
  SERVER_IP: 'https://agents-ss.tlp-ins.co.il',
  ALLOW_ORIGIN: 'https://agents-ss.tlp-ins.co.il'
};


export const config = process.env.REACT_APP_NODE_ENV === 'development' ? dev : prod;
