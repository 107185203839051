import './ModalSet.scss';
import SearchAgreementRow from '../../components/SearchAgreementRow/SearchAgreementRow';
import rightArrow from '../../assets/icons/right-arrow.svg';
import deleteIcon from '../../assets/icons/delete.svg';
import save from '../../assets/icons/save.svg';
import {config} from '../../Constants';
import axios from 'axios';
import React, {useEffect, useRef, useState} from 'react';

export default function ModalSet({
	setShowModal,
	agreements,
	setAgreements,
	currentSetAgreements,
	setCurrentSetAgreements,
	currentSetId,
	setCurrentSetId,
	handlerAddAgreementRow,
	getSetsTable,
	notifyError,
	notifySaveSuccess,
	notifyErrorChoeseAgreements,
	notifyDeleteSuccess,
}) {
	/* Variables */
	const [searchTerm, setSearchTerms] = useState('');
	const [submitError, setSubmitError] = useState(false);
	const [errorNameInput, setErrorNameInput] = useState(false);
	const setNameRef = useRef();
	const searchRef = useRef();
	let searchTimeout = null;

	/* Functions */
	const handleHideModal = () => {
		setShowModal(false);
		// setAgreements([]); //mahmoud
		setCurrentSetAgreements([]);
		setCurrentSetId(null);
	};

	const handlerSearchAgreement = () => {
		if (searchTimeout) {
			clearTimeout(searchTimeout);
		}

		if (searchRef.current.value.length > 0) {
			searchTimeout = setTimeout(() => {
				setSearchTerms(searchRef.current.value);
			}, 500);
		} else {
			setSearchTerms('');
		}
	};

	const handlerSubmit = async () => {
		const set_name = setNameRef.current.value;

		const selectedAgreements = [];

		if (currentSetId) {
			currentSetAgreements.forEach((row) => {
				selectedAgreements.push([currentSetId, row.props.dataAgreement.id_agreement]);
			});

			if (selectedAgreements.length > 0) {
				try {
					const {data} = await axios.post(
						`${config.SERVER_IP}/sets/insertSet`,
						{
							withCredentials: true,
							mode: 'cors',
							credentials: 'include',
							headers: {
								'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
							},
						},
						{
							params: {
								id_set: selectedAgreements[0][0],
								set_name: set_name,
								selectedAgreements: selectedAgreements,
							},
						}
					);
					if (data.result && data.result === 'success') {
						handleHideModal();
						getSetsTable();
						notifySaveSuccess();
					}
				} catch (err) {
					console.log(err);
				}
			}
		} else {
			if (!setNameRef.current.value) {
				setErrorNameInput(true);
				notifyError();
			} else {
				if (!currentSetAgreements.length > 0) {
					notifyErrorChoeseAgreements();
				} else {
					currentSetAgreements.forEach((row) => {
						selectedAgreements.push([row.props.dataAgreement.id_agreement]);
					});
					try {
						const {data} = await axios.post(
							`${config.SERVER_IP}/sets/creatSet`,
							{
								withCredentials: true,
								mode: 'cors',
								credentials: 'include',
								headers: {
									'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
								},
							},
							{
								params: {
									set_name: set_name,
									selectedAgreements: selectedAgreements,
								},
							}
						);
						if (data.result && data.result === 'success') {
							handleHideModal();
							getSetsTable();
							notifySaveSuccess();
						} else {
							// console.log(data);
						}
					} catch (err) {
						console.log(err);
					}
				}
			}
		}
	};

	const handlerDeleteSet = async () => {
		if (currentSetId) {
			try {
				const {data} = await axios.post(
					`${config.SERVER_IP}/sets/deleteSet`,
					{
						withCredentials: true,
						mode: 'cors',
						credentials: 'include',
						headers: {
							'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
						},
					},
					{
						params: {
							id_set: currentSetId,
						},
					}
				);
				if (data.result && data.result === 'success') {
					handleHideModal();
					getSetsTable();
					notifyDeleteSuccess();
				}
			} catch (err) {
				console.log(err);
			}
		}
	};

	useEffect(() => {
		setSubmitError(currentSetAgreements.length === 0);
	}, [currentSetAgreements.length]);

	return (
		<div className="modal-bg">
			<div className="modal-set">
				<div className="inner">
					<div className="buttons">
						<button className="back" onClick={handleHideModal}>
							<img src={rightArrow} alt="" />
							<span>חזרה לדף סטים</span>
						</button>
						<button className="deleteSet" onClick={handlerDeleteSet}>
							<img src={deleteIcon} alt="" />
							<span>למחוק סט</span>
						</button>
					</div>

					<h2>עריכת סט</h2>

					<input
						ref={setNameRef}
						type="text"
						className={errorNameInput ? 'set-name error' : 'set-name'}
						name="set-name"
						defaultValue={`${
							currentSetAgreements.length > 0 ? currentSetAgreements[0].props.dataAgreement.set_name : ''
						}`}
						placeholder="שם הסט"
						onChange={(e) => {
							if (e.target.value !== '') {
								setErrorNameInput(false);
							}
						}}
					/>

					<p className="title">הסכמים משוייכים לסט</p>

					<div className="container-agreements">{currentSetAgreements}</div>

					<div className="line"></div>

					<input
						ref={searchRef}
						type="text"
						className="search-agreement"
						name="search-agreement"
						placeholder="חיפוש הסכם להוספה"
						onInput={handlerSearchAgreement}
					/>

					{searchTerm.length > 0 && <p className="title">תוצאות חיפוש</p>}

					<div className="container-results">
						{searchTerm &&
							agreements &&
							agreements.map((row, indexRow) => {
								let flagExists = false;

								for (let agreement of currentSetAgreements) {
									if (agreement.props.dataAgreement.id_agreement === row.id_agreement) {
										flagExists = true;
										break;
									}
								}

								return (
									!flagExists &&
									row.name.includes(searchTerm) && (
										<SearchAgreementRow
											index={indexRow}
											key={indexRow}
											dataAgreement={row}
											handlerAddAgreementRow={handlerAddAgreementRow}
										/>
									)
								);
							})}
					</div>

					<button className="submit-button" onClick={handlerSubmit}>
						<img src={save} alt="save" />
						<span>שמירה</span>
					</button>

					{submitError && <p className="error">לא נבחרו חוזים</p>}
				</div>
			</div>
		</div>
	);
}
