//import { useEffect, useRef, useState } from "react"
import React, {useState} from 'react';
import './ModalFlow.scss';
import rightArrow from '../../assets/icons/right-arrow.svg';
import x_icon from '../../assets/icons/x.svg';
import excel from '../../assets/icons/excel.svg';
import plus from '../../assets/icons/plus.svg';
import save from '../../assets/icons/save.svg';
import TableFlow from '../../components/TableFlow/TableFlow';
import {config} from '../../Constants';
import writeXlsxFile from 'write-excel-file';
import axios from 'axios';

export default function ModalFlow({name, idAgent, flowRows, setFlowRows, setShowFlowModal}) {
	const handlerRemoveFlow = (e) => {
		setNumRowForm((prevState) => {
			return {
				items: prevState.items.filter(function (item, i) {
					return i !== parseInt(e.target.getAttribute('data-num'));
				}),
			};
		});
	};

	/* Variables */
	const [sum, setSum] = useState('');

	const [numRowForm, setNumRowForm] = useState({
		items: [],
	});

	/* Functions */
	const handleHideModal = () => {
		setShowFlowModal(false);
	};

	const handlerSubmit = async () => {
		const agentFlows = [];

		for (let index = 0; index < flowRows.length; index++) {
			agentFlows.push([
				idAgent,
				document.getElementById('cell-flow-date' + index).innerText,
				document.getElementById('cell-flow-amount' + index).innerText,
				document.getElementById('cell-flow-comment' + index).innerText,
			]);
		}

		for (let index = 0; index < numRowForm.items.length; index++) {
			agentFlows.push([
				idAgent,
				document.getElementById('input-flow-date' + index).value,
				document.getElementById('input-flow-amount' + index).value,
				document.getElementById('input-flow-comment' + index).value,
			]);
		}

		const params = {
			idAgent: idAgent,
			agentFlows: agentFlows,
		};

		try {
			const {data} = await axios.post(
				`${config.SERVER_IP}/agents/insertFlows`,
				{
					withCredentials: true,
					mode: 'cors',
					credentials: 'include',
					headers: {
						'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
					},
				},
				{params: params}
			);

			if (data.result && data.result === 'success') {
				setShowFlowModal(false);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handlerAddFlow = () => {
		setNumRowForm((prevState) => {
			return {
				items: [
					...prevState.items,
					<div className="row-form" key={prevState.items.length}>
						<div className="cell cell-remove">
							<img
								data-num={prevState.items.length}
								src={x_icon}
								alt="delete"
								onClick={handlerRemoveFlow}
							/>
						</div>
						<div className="cell">
							<input id={`input-flow-date${prevState.items.length}`} type="date" />
						</div>
						<div className="cell">
							<input id={`input-flow-amount${prevState.items.length}`} type="number" />
						</div>
						<div className="cell">
							<input id={`input-flow-comment${prevState.items.length}`} type="text" />
						</div>
					</div>,
				],
			};
		});
	};

	const handlerExport = async () => {
		const tableRows = document.querySelectorAll('.flow-table .row');
		const arrayToExport = [];

		tableRows.forEach((row) => {
			const rowCells = row.querySelectorAll('.cell:not(.cell-remove)');
			const rowArray = [];

			rowCells.forEach((cell) => {
				rowArray.push({
					value: cell.innerText,
					width: 13,
					height: 25,
					align: 'center',
					fontWeight: row.classList.contains('head') ? 'bold' : '400',
				});
			});

			arrayToExport.push(rowArray);
		});

		const columns = [
			{width: 15},
			{width: 15},
			{width: 15},
			{width: 15},
			{width: 15},
			{width: 15},
			{width: 15},
			{width: 15},
			{width: 15},
			{width: 15},
		];

		await writeXlsxFile(arrayToExport, {
			columns: columns,
			fileName: `טבלת תזרים ${name}.xlsx`,
		});
	};

	return (
		<div className="modal-bg">
			<div className="modal-flow">
				<div className="inner">
					<button className="back" onClick={handleHideModal}>
						<img src={rightArrow} alt="" />
						<span>חזרה לדף סוכנים</span>
					</button>

					<h2>תזרים {name}</h2>

					<button className="export-file" onClick={handlerExport}>
						<img src={excel} width="20" alt="excel" />
						<span>יצוא טבלת אקסל</span>
					</button>

					<div className="container-content">
						<TableFlow
							flowRows={flowRows}
							setFlowRows={setFlowRows}
							setSum={setSum}
							numRowForm={numRowForm}
						/>

						<div className="row-add">
							<button className="add" onClick={handlerAddFlow}>
								<img src={plus} alt="add" />
							</button>
						</div>

						<div className="row-bottom">
							<div className="sum">
								<span>תזרים</span>
								<p>{sum}</p>
							</div>

							<button className="submit-button" onClick={handlerSubmit}>
								<img src={save} alt="save" />
								<span>שמירה</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
