import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import AgentNumberRow from '../../components/AgentNumberRow/AgentNumberRow';
import MyDrop from '../../components/MyDrop/MyDrop';
import rightArrow from '../../assets/icons/right-arrow.svg';
import plus from '../../assets/icons/plus.svg';
import save from '../../assets/icons/save.svg';
import { config } from '../../Constants';
import './ModalNewAgent.scss';

export default function ModalNewAgent({
  setCurrentAgent,
  setShowModal,
  getAgentsTable,
  currentAgent,
}) {
  /* Functions */
  const handleHideModal = () => {
    setCurrentAgent(null);
    setShowModal(false);
  };

  const getSetOptions = async () => {
    try {
      const { data } = await axios.post(
        `${config.SERVER_IP}/sets/getSets`,
        {},
        {
          withCredentials: true,
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
          },
          params: {},
        }
      );

      if (data && data.rowsData) {
        const arr = [];

        data.rowsData.forEach((item, index) => {
          arr.push({
            id_set: item.id_set,
            set_name: item.set_name,
          });
        });

        setSetOptions(arr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlerAdd = () => {
    setAgentNumberRows((prevState) => {
      return [
        ...prevState,
        <AgentNumberRow
          key={prevState.length}
          index={prevState.length}
          handlerRemove={handlerRemove}
          companyOptions={companyOptions}
        />,
      ];
    });
  };

  const handlerRemove = (index) => {
    setAgentNumberRows((prev) => {
      let copy = [...prev];
      copy = copy.filter((item, i) => parseInt(item.key) !== index);
      return copy;
    });
  };

  const handlerSubmit = async () => {
    let flagValidate = true;

    const name = nameRef.current.value;
    const business = businessRef.current.value;
    const id = idRef.current.value;
    const start = startRef.current.value;
    const end = endRef.current.value;
    const active = isActive;
    const set = setOptions.length > 0 ? setOptions[setIndex].id_set : 0;
    const comment = commentRef.current.value;

    nameRef.current.classList.remove('error');
    businessRef.current.classList.remove('error');
    idRef.current.classList.remove('error');
    startRef.current.classList.remove('error');
    endRef.current.classList.remove('error');

    if (!name) {
      nameRef.current.classList.add('error');
      flagValidate = false;
    }

    if (!business) {
      businessRef.current.classList.add('error');
      flagValidate = false;
    }

    if (!id) {
      idRef.current.classList.add('error');
      flagValidate = false;
    }

    // if (!start) {
    // 	startRef.current.classList.add('error');
    // 	flagValidate = false;
    // }

    // if (!end) {
    // 	endRef.current.classList.add('error');
    // 	flagValidate = false;
    // }

    const agentNumbers = [];

    document.querySelectorAll('.agent-number-row').forEach((element) => {
      agentNumbers.push([
        id,
        element.getAttribute('data-number'),
        element.getAttribute('data-company'),
      ]);
    });

    const params = {
      name: name,
      business: business,
      id: id,
      start: start,
      end: end,
      active: active,
      set: set,
      comment: comment,
      agentNumbers: agentNumbers,
    };

    if (flagValidate) {
      try {
        const { data } = await axios.post(
          `${config.SERVER_IP}/agents/insertAgent`,
          {},
          {
            withCredentials: true,
            mode: 'cors',
            credentials: 'include',
            headers: {
              'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
            },
            params: params,
          }
        );

        if (data.result && data.result === 'success') {
          setCurrentAgent(null);
          setShowModal(false);
          getAgentsTable();
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handlerDelete = async () => {
    if (confirmDelete) {
      const id = idRef.current.value;

      const params = {
        id: id,
      };

      try {
        const { data } = await axios.post(
          `${config.SERVER_IP}/agents/deleteAgent`,
          {},
          {
            withCredentials: true,
            mode: 'cors',
            credentials: 'include',
            headers: {
              'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
            },
            params: params,
          }
        );

        if (data.result && data.result === 'success') {
          setCurrentAgent(null);
          setShowModal(false);
          getAgentsTable();

          setConfirmDelete(false);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      setConfirmDelete(true);
    }
  };

  const getCompaniesOptions = async () => {
    try {
      const { data } = await axios.post(
        `${config.SERVER_IP}/settings/getCompanies`,
        {},
        {
          withCredentials: true,
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
          },
          params: {},
        }
      );

      if (data && data.rowsData) {
        const arr = [];

        data.rowsData.forEach((item, index) => {
          arr.push(item.name);
        });

        setCompanyOptions(arr);

        !currentAgent &&
          setAgentNumberRows([
            <AgentNumberRow
              key={0}
              index={0}
              handlerRemove={handlerRemove}
              companyOptions={arr}
            />,
          ]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  /* Variables */
  const nameRef = useRef();
  const businessRef = useRef();
  const idRef = useRef();
  const startRef = useRef();
  const endRef = useRef();
  const commentRef = useRef();

  const [companyOptions, setCompanyOptions] = useState([]);

  const [agentNumberRows, setAgentNumberRows] = useState([]);
  const [isActive, setIsActive] = useState(1);

  const [setOptions, setSetOptions] = useState([]);
  const [setIndex, setSetIndex] = useState(0);

  const [confirmDelete, setConfirmDelete] = useState(false);

  const getAgentNumberRows = () => {
    if (!currentAgent || setOptions.length === 0) return;

    nameRef.current.value = currentAgent?.fullName;
    businessRef.current.value = currentAgent?.business;
    idRef.current.value = currentAgent?.id_agents;
    startRef.current.value = currentAgent?.start;
    endRef.current.value = currentAgent?.end;

    setIsActive(parseInt(currentAgent.active));

    setOptions.forEach((item_obj, index) => {
      if (item_obj.id_set === currentAgent.id_set) {
        setSetIndex(index);
      }
    });
    commentRef.current.value = currentAgent.comment;

    setAgentNumberRows([...currentAgent.rowsNumbers]);
  };

  /* Triggers */
  useEffect(() => {
    getSetOptions();
    getCompaniesOptions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAgentNumberRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAgent, setOptions.length]);

  return (
    <div className='modal-bg'>
      <div className='modal-new-agent'>
        <div className='inner'>
          <button className='back' onClick={handleHideModal}>
            <img src={rightArrow} alt='' />
            <span>חזרה לדף סוכנים</span>
          </button>

          <h2>הגדרת סוכן</h2>

          <div className='container-halves'>
            <div className='half1'>
              <div className='row'>
                <div className='cell'>
                  <label htmlFor='fullName'>שם הסוכן</label>
                  <input
                    ref={nameRef}
                    type='text'
                    name='fullName'
                    id='fullName'
                    placeholder='שם הסוכן'
                  />
                </div>

                <div className='cell'>
                  <label htmlFor='business'>שם העסק</label>
                  <input
                    ref={businessRef}
                    type='text'
                    name='business'
                    id='business'
                    placeholder='שם העסק'
                  />
                </div>

                <div className='cell'>
                  <label htmlFor='id'>תעודת זהות</label>
                  <input
                    ref={idRef}
                    type='text'
                    name='id'
                    id='id'
                    placeholder='תעודת זהות'
                  />
                </div>
              </div>

              <div className='row'>
                <div className='cell'>
                  <label htmlFor='start'>פעיל החל מ</label>
                  <input
                    ref={startRef}
                    type='date'
                    name='start'
                    id='start'
                    placeholder='בחרו תאריך'
                  />
                </div>

                <div className='cell'>
                  <label htmlFor='end'>פעיל עד</label>
                  <input
                    ref={endRef}
                    type='date'
                    name='end'
                    id='end'
                    placeholder='בחרו תאריך'
                  />
                </div>

                <div className='cell'>
                  <label htmlFor='status'>סטטוס</label>
                  <MyDrop
                    colorizeSwitchOptions='true'
                    className='status'
                    optionsArr={['לא פעיל', 'פעיל']}
                    initValueIndex={isActive}
                    handler={setIsActive}
                  />
                </div>
              </div>

              <div className='row'>
                <div className='cell'>
                  <label htmlFor='set'>סט</label>
                  <MyDrop
                    className='set'
                    optionsArr={setOptions}
                    initValueIndex={setIndex}
                    handler={setSetIndex}
                    attributeToPrint={'set_name'}
                  />
                </div>
              </div>

              <div className='row'>
                <div className='cell'>
                  <label htmlFor='comment'>הערות</label>
                  <textarea
                    ref={commentRef}
                    name='comment'
                    id='comment'
                    rows='4'
                    placeholder='הערות'
                  ></textarea>
                </div>
              </div>

              <div className='row-actions'>
                {currentAgent && (
                  <button className='delete-button' onClick={handlerDelete}>
                    <span>{confirmDelete ? 'אישור מחיקה' : 'מחיקת סוכן'}</span>
                  </button>
                )}

                <button className='submit-button' onClick={handlerSubmit}>
                  <img src={save} alt='save' />
                  <span>שמירה</span>
                </button>
              </div>
            </div>

            <div className='half2'>
              {agentNumberRows.map((item, index) => (
                <AgentNumberRow
                  key={index}
                  index={index}
                  handlerRemove={handlerRemove}
                  company_name={item.company_name}
                  agent_number={item.agent_number}
                  companyOptions={companyOptions}
                />
              ))}

              <div className='row'>
                <img
                  className='add'
                  onClick={handlerAdd}
                  src={plus}
                  alt='add'
                />
              </div>

              <div className='row-actions mobile'>
                {currentAgent && (
                  <button className='delete-button' onClick={handlerDelete}>
                    <span>{confirmDelete ? 'אישור מחיקה' : 'מחיקת סוכן'}</span>
                  </button>
                )}

                <button className='submit-button' onClick={handlerSubmit}>
                  <img src={save} alt='save' />
                  <span>שמירה</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
