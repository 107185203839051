import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import './TableAgents.scss';
import flowIcon from '../../assets/icons/flowIcon.svg';
import ModalFlow from '../../components/ModalFlow/ModalFlow';
import {format_date} from '../../pages/utils/helpers';
import axios from 'axios';
import {config} from '../../Constants';

function TableAgents({rows, handlerRowClick}) {
	/* Variables */
	const [showFlowModal, setShowFlowModal] = useState(false);
	const [flowName, setFlowName] = useState('');
	const [flowIdAgent, setFlowIdAgent] = useState('');
	const [flowRows, setFlowRows] = useState([]);

	/* Functions */
	const handleShowFlowModal = (e) => {
		const current_flow_id = e.target.getAttribute('data-id-agent');
		const current_flow_name = e.target.getAttribute('data-name');

		getAgentFlow(current_flow_id);
		setFlowName(current_flow_name);
		setFlowIdAgent(current_flow_id);

		setShowFlowModal(true);
	};

	const getAgentFlow = async (current_flow_id) => {
		try {
			const {data} = await axios.post(
				`${config.SERVER_IP}/agents/getAgentFlow`,
				{
					withCredentials: true,
					mode: 'cors',
					credentials: 'include',
					headers: {
						'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
					},
				},
				{params: {current_flow_id: current_flow_id}}
			);

			if (data && data.rowsData) {
				setFlowRows(data.rowsData);
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<div className="table agents-table">
			<div className="row head" key={'head'}>
				<div className="cell">סוכן</div>
				<div className="cell">חברה</div>
				<div className="cell">סט</div>
				<div className="cell">הערה</div>
				<div className="cell">סטאטוס</div>
				<div className="cell">תחילת פעילות</div>
				<div className="cell">סוף פעילות</div>
				<div className="cell"></div>
			</div>

			{rows &&
				rows.map((row, indexRow) => (
					<div
						className="row"
						key={indexRow}
						onClick={(e) => {
							handlerRowClick(e, row.id_agents);
						}}
					>
						<div className="cell">{row.fullName}</div>
						<div className="cell">{row.business}</div>
						<div className="cell">{row.set_name}</div>
						<div className="cell">{row.comment}</div>
						<div className={`cell ${parseInt(row.active) === 1 ? 'active' : 'not-active'}`}>{`${
							parseInt(row.active) === 1 ? 'פעיל' : 'לא פעיל'
						}`}</div>
						<div className="cell">{row.start ? format_date(row.start) : ''}</div>
						<div className="cell">{row.end ? format_date(row.end) : ''}</div>
						<div className="cell flow-cell">
							<button
								className="agent-flow"
								data-id-agent={row.id_agents}
								data-name={row.fullName}
								onClick={handleShowFlowModal}
							>
								<img src={flowIcon} alt="" />
								<span>תזרים</span>
							</button>
						</div>
					</div>
				))}

			{
				showFlowModal && (
					// ReactDOM.createPortal(
					<ModalFlow
						idAgent={flowIdAgent}
						name={flowName}
						flowRows={flowRows}
						setFlowRows={setFlowRows}
						setShowFlowModal={setShowFlowModal}
					/>
				)
				// document.getElementById('modal-root')
				// )
			}
		</div>
	);
}

export default TableAgents;
