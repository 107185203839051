import React, { useEffect, useState } from 'react';
import './Agents.scss';
import TopLine from '../../components/TopLine/TopLine';
import { ExcelRenderer } from 'react-excel-renderer';
import ReactDOM from 'react-dom';
import TableAgents from '../../components/TableAgents/TableAgents';
import 'react-dropdown/style.css';
import ModalNewAgent from '../../components/ModalNewAgent/ModalNewAgent';
import excel from '../../assets/icons/excel.svg';
import agentIcon from '../../assets/icons/newAgent.svg';
import axios from 'axios';
import { config } from '../../Constants';
// import AbortController from 'abort-controller';

export default function Agents({ data }) {
  /* Variables */
  const [rows, setRows] = useState([]);
  const [searchRows, setSearchRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentAgent, setCurrentAgent] = useState(null);
  const [loadingFile, setLoadingFile] = useState(false);
  const inputFileRef = React.useRef();

  /* Functions insert excel */
  const excelHandler = (event) => {
    setLoadingFile(true);
    let fileObj = event.target.files[0];

    // pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
        setLoadingFile(false);
      } else {
        let controller = new AbortController();

        insertAgentFile(resp.rows, controller);
      }
    });
  };

  const insertAgentFile = async (rows, controller) => {
    try {
      const { data } = await axios.post(
        `${config.SERVER_IP}/agents/insertAgentsFile`,
        {
          data: rows,
        },
        {
          // signal: controller.signal,
          withCredentials: true,
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
          },
        }
      );

      if (data.result === 'success') {
        getAgentsTable();
        setLoadingFile(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  /* Functions */
  const getAgentsTable = async () => {
    try {
      const { data } = await axios.post(
        `${config.SERVER_IP}/agents/getAgentsTable`,
        {},
        {
          withCredentials: true,
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
          },
          params: {},
        }
      );
      setRows(data.rowsData);
      setSearchRows(data.rowsData);
    } catch (err) {
      console.log(err);
    }
  };

  const searchInput = (e) => {
    setSearchRows(
      rows.filter((cell) => cell.fullName.includes(e.target.value))
    );
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handlerRowClick = async (e, id_agent) => {
    if (e.target.className === 'row') {
      try {
        const { data } = await axios.post(
          `${config.SERVER_IP}/agents/getAgent`,
          {},
          {
            withCredentials: true,
            mode: 'cors',
            credentials: 'include',
            headers: {
              'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
            },
            params: { id_agent: id_agent },
          }
        );

        if (data.rowsData[0]?.id_agents) {
          data.rowsData[0].rowsNumbers = data.rowsNumbers;
          setCurrentAgent(data.rowsData[0]);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  /* Triggers */
  useEffect(() => {
    getAgentsTable();
  }, []);

  useEffect(() => {
    currentAgent && handleShowModal();
  }, [currentAgent]);

  return (
    <div className='page agents'>
      <TopLine title={data.name} />

      <div className='sub-top-line'>
        <input
          className='search'
          type='text'
          placeholder='חיפוש'
          onInput={searchInput}
        />

        <button
          className='add-agent'
          onClick={() => {
            setCurrentAgent(null);
            handleShowModal();
          }}
        >
          <img src={agentIcon} alt='' />
          <span>הוספת סוכן</span>
        </button>
        <button
          className={`load-file ${loadingFile ? 'loading' : ''}`}
          onClick={() => {
            inputFileRef.current.click();
          }}
        >
          <img src={excel} alt='excel' />
          <span>{`${loadingFile ? 'טוען...' : 'הזנת קובץ אקסל'}`}</span>
          <input
            type='file'
            onChange={excelHandler}
            style={{ padding: '10px', display: 'none' }}
            ref={inputFileRef}
          />
        </button>
      </div>

      <div className='content'>
        <TableAgents rows={searchRows} handlerRowClick={handlerRowClick} />
      </div>

      {
        showModal && (
          // ReactDOM.createPortal(
          <ModalNewAgent
            setCurrentAgent={setCurrentAgent}
            setShowModal={setShowModal}
            getAgentsTable={getAgentsTable}
            currentAgent={currentAgent}
          />
        )
        // 	,
        // 	document.getElementById('modal-root')
        // )
      }
    </div>
  );
}
