import AgentRow from '../../components/AgentRow/AgentRow';
import AgentSumRow from '../../components/AgentSumRow/AgentSumRow';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import './TableAgent.scss';

function TableAgent({ rows, agentAgreementsProp, deviation, loadMore }) {
  /* Local variables */
  const [arrayShown, setArrayShown] = useState([]);
  const [agentAgreements, setAgentAgreements] = useState([]);
  const [loading, setLoading] = useState(false); // Add loading state
  const tableRef = useRef(null);

  let currentAgentFullName = '';
  let currentAgentNumber = '';
  let currentCompany = '';
  let currentTopic = '';
  let currentSubTopic = '';
  let currentYear = '';
  let totalSales = 0;
  let totalCancellations = 0;
  let totalAgentFee = 0;
  let totalTalpiotFee = 0;
  let totalAgentCompletion = 0;
  let groupRowsIdentifier = ''; // a key used to identify group of rows with the same attributes (company, topic, subtopic)
  let rowAgreements = [];

  /* Functions */
  const handlerSumClick = (e) => {
    const identify = e.target.getAttribute('data-identify');

    if (!arrayShown.includes(identify)) {
      setArrayShown((prevState) => [...prevState, identify]);
      return;
    }

    setArrayShown((prevState) =>
      prevState.filter((identifier, i) => identifier !== identify)
    );
  };

  const handleScroll = useCallback(() => {
    if (tableRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (!loading) {
          setLoading(true);
          loadMore().finally(() => setLoading(false));
        }
      }
    }
  }, [loadMore, loading]);

  useEffect(() => {
    const tableElement = tableRef.current;
    if (tableElement) {
      tableElement.addEventListener('scroll', handleScroll);
      return () => {
        tableElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll]);

  /* Triggers */

  useEffect(() => {
    setAgentAgreements(agentAgreementsProp);
  }, [agentAgreementsProp]);

  //   console.log({ agentAgreements });
  //   console.log({ rows });

  return (
    <div ref={tableRef} className='table agent-table'>
      {loading && (
        <div className='loading-spinner-container'>
          <div className='loading-spinner'>Loading...</div>
        </div>
      )}
      <div className='row head' key={'head'}>
        <div className='cell'>סוכן</div>
        <div className='cell'>מספר סוכן</div>
        <div className='cell'>חברה</div>
        <div className='cell'>תחום</div>
        <div className='cell'>סוג</div>
        <div className='cell'>סכום מכירות כולל</div>
        <div className='cell'>ביטולים</div>
        <div className='cell'>ע.הקפים לסוכן</div>
        <div className='cell'>ע. הקפים תלפיות</div>
        <div className='cell'>השלמות לסוכן</div>
        <div className='cell'>שנה</div>
      </div>

      {rows?.length &&
        rows?.map((row, indexRow) => {
          let flagSum = false;
          let sumContent = '';
          let flagEnd = false;
          let endContent = '';

          const agentAgreement = agentAgreements.find(
            (agreement) => agreement.fullName === row.fullName
          );

          // SUM ROW
          if (
            indexRow !== 0 &&
            row.agent_number !== currentAgentNumber
            // ||
            //   row.company !== currentCompany ||
            //   row.insuranceTopic !== currentTopic ||
            //   row.insuranceSubtopic !== currentSubTopic ||
            //   row.year !== currentYear
          ) {
            flagSum = true;
            const prevRow = rows[indexRow - 1];

            // find the agreement for that matches this row
            agentAgreements.forEach((agreement, index) => {
              if (
                agreement.fullName === prevRow.fullName &&
                agreement.company === prevRow.company
                // agreement.topic === prevRow.insuranceTopic &&
                // agreement.subtopic === prevRow.insuranceSubtopic
                // agreement.year === prevRow.year
              ) {
                rowAgreements.push(agreement);
              }
            });

            sumContent = (
              <AgentSumRow
                deviation={deviation}
                arrayShown={arrayShown}
                groupRowsIdentifier={groupRowsIdentifier}
                indexRow={indexRow}
                rowAgreements={rowAgreements}
                totalSales={totalSales}
                totalCancellations={totalCancellations}
                totalAgentFee={totalAgentFee}
                totalTalpiotFee={totalTalpiotFee}
                totalAgentCompletion={totalAgentCompletion}
                currentAgentFullName={currentAgentFullName}
                currentYear={currentYear}
                currentAgentNumber={currentAgentNumber}
                currentCompany={currentCompany}
                currentTopic={currentTopic}
                currentSubTopic={currentSubTopic}
                handlerSumClick={handlerSumClick}
                agreement={row}
                agreementAgreementReward={row.agreementReward}
              />
            );

            currentAgentFullName = '';
            currentAgentNumber = '';
            currentCompany = '';
            currentTopic = '';
            currentSubTopic = '';
            currentYear = '';
            totalSales = 0;
            totalCancellations = 0;
            totalAgentFee = 0;
            totalTalpiotFee = 0;
            totalAgentCompletion = 0;
            groupRowsIdentifier = '';
            // rowAgreements = [];
          }

          currentAgentFullName = row.fullName;
          currentAgentNumber = row.agent_number;
          currentCompany = row.company;
          currentTopic = row.insuranceTopic;
          currentSubTopic = row.insuranceSubtopic;
          currentYear = row.year;
          totalSales += row.sales;
          totalCancellations += row.cancellations;
          totalAgentFee += row.agentFee;
          totalTalpiotFee += row.talpiotFee;
          totalAgentCompletion += row.agentCompletion;

          if (groupRowsIdentifier === '') {
            groupRowsIdentifier = btoa(
              unescape(
                encodeURIComponent(
                  currentAgentFullName +
                    currentCompany +
                    // currentTopic +
                    // currentSubTopic +
                    currentYear
                )
              )
            );
          }

          // LAST SUM
          if (indexRow === rows.length - 1) {
            flagEnd = true;

            agentAgreements.forEach((agreement, index) => {
              if (
                agreement.fullName === row.fullName &&
                agreement.company === row.company
                // &&
                // agreement.topic === row.insuranceTopic &&
                // agreement.subtopic === row.insuranceSubtopic
                // agreement.year === row.year
              ) {
                rowAgreements.push(agreement);
              }
            });

            endContent = (
              <AgentSumRow
                deviation={deviation}
                arrayShown={arrayShown}
                groupRowsIdentifier={groupRowsIdentifier}
                indexRow={indexRow}
                rowAgreements={rowAgreements}
                totalSales={totalSales}
                totalCancellations={totalCancellations}
                totalAgentFee={totalAgentFee}
                totalTalpiotFee={totalTalpiotFee}
                totalAgentCompletion={totalAgentCompletion}
                currentAgentFullName={currentAgentFullName}
                currentYear={currentYear}
                currentAgentNumber={currentAgentNumber}
                currentCompany={currentCompany}
                currentTopic={currentTopic}
                currentSubTopic={currentSubTopic}
                handlerSumClick={handlerSumClick}
                agreement={row}
                agreementAgreementReward={row.agreementReward}
              />
            );
          }

          return (
            <React.Fragment key={indexRow}>
              {flagSum && sumContent}

              <AgentRow
                arrayShown={arrayShown}
                groupRowsIdentifier={groupRowsIdentifier}
                indexRow={indexRow}
                row={row}
              />
              {flagEnd && endContent}
            </React.Fragment>
          );
        })}
    </div>
  );
}

export default TableAgent;
