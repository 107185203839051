import './TableSets.scss';
import React from 'react';

function TableSets({rows, handlerRowClick}) {
	/* Functions */

	return (
		<div className="table sets-table">
			<div className="row head" key={'head'}>
				<div className="cell">שם הסט</div>
			</div>

			{rows &&
				rows.map((row, indexRow) => {
					return (
						<div
							className="row"
							key={indexRow}
							onClick={(e) => {
								handlerRowClick(e, row.id_set);
							}}
						>
							<div className="cell" key={indexRow + '_0'}>
								{row.set_name}
							</div>
						</div>
					);
				})}
		</div>
	);
}

export default TableSets;
