import './SearchAgreementRow.scss';
import React from 'react';
import {getAgreementTypeName} from '../../pages/utils/helpers';

export default function SearchAgreementRow({index, dataAgreement, handlerAddAgreementRow}) {
	return (
		<div
			className="row search-agreement-row"
			key={index}
			data-agreement-id={dataAgreement.name}
			onClick={() => {
				handlerAddAgreementRow(dataAgreement);
			}}
		>
			{dataAgreement && dataAgreement.name && (
				<>
					<div className="cell">{dataAgreement.name}</div>
					<div className="cell">{dataAgreement.company}</div>
					<div className="cell">{dataAgreement.topic}</div>
					<div className="cell">{dataAgreement.subtopic}</div>
					<div className="cell">{dataAgreement.start}</div>
					<div className="cell">{dataAgreement.end}</div>
					<div className="cell">{getAgreementTypeName(dataAgreement.type)}</div>
				</>
			)}
		</div>
	);
}
