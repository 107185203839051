import React, {useContext} from 'react';
import './TopLine.scss';
import {AuthContext} from '../../context/AuthContext';
import hamburger from '../../assets/icons/hamburger.svg';

export default function TopLine({title}) {
	/* Variables */
	const {isSideBarOpen, setIsSideBarOpen} = useContext(AuthContext);

	/* Functions */
	const clickHandler = () => {
		setIsSideBarOpen((prev) => !prev);
	};

	return (
		<div className="top-line">
			<h1>{title}</h1>

			<img
				className={`hamburger ${isSideBarOpen ? 'open' : ''}`}
				src={hamburger}
				alt="menu"
				onClick={clickHandler}
			/>
		</div>
	);
}
