import MyDrop from '../../components/MyDrop/MyDrop';
import React, {useEffect, useRef, useState} from 'react';
import './AgentNumberRow.scss';

export default function AgentNumberRow({index, handlerRemove, company_name = '', agent_number = '', companyOptions}) {
	/* Variables */
	const [companyIndex, setCompanyIndex] = useState(0);
	const [number, setNumber] = useState(agent_number);
	const numberInput = useRef();

	/* Functions */
	const handlerNumber = () => {
		setNumber(numberInput.current.value);
	};

	useEffect(() => {
		if (company_name && companyOptions.length > 0) {
			setCompanyIndex(companyOptions.indexOf(company_name));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [company_name, companyOptions.length]);

	return (
		<div
			className="row agent-number-row"
			key={index}
			data-company={companyOptions[companyIndex]}
			data-number={number}
		>
			<div className="cell">
				<label htmlFor="company">חברה</label>
				<MyDrop
					className="company"
					optionsArr={companyOptions}
					initValueIndex={companyIndex}
					handler={setCompanyIndex}
				/>
			</div>

			<div className="cell">
				<label htmlFor="agent-number">מספר סוכן בחברה</label>
				<input
					className="agent-number"
					ref={numberInput}
					onInput={handlerNumber}
					type="text"
					defaultValue={agent_number}
					name="agent-number"
					placeholder="מספר סוכן"
				/>
			</div>

			<div className="cell remove">
				<span
					onClick={() => {
						handlerRemove(index);
					}}
				>
					x
				</span>
			</div>
		</div>
	);
}
