import { Route, Routes } from 'react-router-dom';
import React, { useContext } from 'react';
import './App.scss';
import Sidebar from './components/Sidebar/Sidebar';
import PrivateRoute from './pages/utils/PrivateRoute';
import Login from './pages/Login/Login';
import Reset from './pages/Reset/Reset';
import Agent from './pages/Agent/Agent';
import Agents from './pages/Agents/Agents';
import Agreements from './pages/Agreements/Agreements';
import Sets from './pages/Sets/Sets';
import Companies from './pages/Companies/Companies';
import Settings from './pages/Settings/Settings';
import agent_icon from './assets/icons/agent.svg';
import agents_icon from './assets/icons/agents.svg';
import agents_agree_icon from './assets/icons/agreements.svg';
import sets_icon from './assets/icons/papers.svg';
import companies_icon from './assets/icons/companies.svg';
import settings_icon from './assets/icons/settings.svg';
import { AuthContext } from './context/AuthContext';

function App() {
  /* Variables */
  const navItems = [
    {
      path: '/',
      name: 'סוכן',
      icon: agent_icon,
    },
    {
      path: '/agents',
      name: 'סוכנים',
      icon: agents_icon,
    },
    {
      path: '/agreements',
      name: 'הסכמים',
      icon: agents_agree_icon,
    },
    {
      path: '/sets',
      name: 'סטים',
      icon: sets_icon,
    },
    {
      path: '/companies',
      name: 'חברות',
      icon: companies_icon,
    },
    {
      path: '/settings',
      name: 'הגדרות',
      icon: settings_icon,
    },
  ];

  const { isLogged, setIsLogged } = useContext(AuthContext);

  return (
    <div className='app'>
      {isLogged && <Sidebar navItems={navItems} />}

      <Routes>
        <Route path='/login' element={<Login />} />

        <Route path='/reset' element={<Reset />} />

        <Route exact path='/' element={<PrivateRoute />}>
          <Route
            path={navItems[0].path}
            element={<Agent data={navItems[0]} />}
          />
        </Route>

        <Route exact path='/agents' element={<PrivateRoute />}>
          <Route
            path={navItems[1].path}
            element={<Agents data={navItems[1]} />}
          />
        </Route>

        <Route exact path='/agreements' element={<PrivateRoute />}>
          <Route
            path={navItems[2].path}
            element={<Agreements data={navItems[2]} />}
          />
        </Route>

        <Route exact path='/sets' element={<PrivateRoute />}>
          <Route
            path={navItems[3].path}
            element={<Sets data={navItems[3]} />}
          />
        </Route>

        <Route exact path='/companies' element={<PrivateRoute />}>
          <Route
            path={navItems[4].path}
            element={<Companies data={navItems[4]} />}
          />
        </Route>

        <Route exact path='/settings' element={<PrivateRoute />}>
          <Route
            path={navItems[5].path}
            element={<Settings data={navItems[5]} />}
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
