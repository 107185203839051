import './Sets.scss';
import setIcon from '../../assets/icons/papers.svg';
import SetAgreementRow from '../../components/SetAgreementRow/SetAgreementRow';
import TopLine from '../../components/TopLine/TopLine';
import ModalSet from '../../components/ModalSet/ModalSet';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TableSets from '../../components/TableSets/TableSets';
import ReactDOM from 'react-dom';
import 'react-dropdown/style.css';
import { config } from '../../Constants';

// laibary alert
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Sets({ data }) {
  /* Variables */
  const [rows, setRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [agreements, setAgreements] = useState([]);
  const [currentSetAgreements, setCurrentSetAgreements] = useState([]);
  const [currentSetId, setCurrentSetId] = useState(null);

  /* Functions */

  const getSetsTable = async () => {
    try {
      const { data } = await axios.post(
        `${config.SERVER_IP}/sets/getSetsTable`,
        {},
        {
          withCredentials: true,
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
          },
          params: {},
        }
      );

      if (data && data.rowsData) {
        setRows(data.rowsData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handlerRowClick = async (e, id_set) => {
    if (e.target.className === 'row') {
      setCurrentSetId(id_set);

      try {
        const { data } = await axios.post(
          `${config.SERVER_IP}/sets/getSetAgreements`,
          {},
          {
            withCredentials: true,
            mode: 'cors',
            credentials: 'include',
            headers: {
              'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
            },
            params: { id_set: id_set },
          }
        );

        if (data && data.rowsData) {
          const arrRows = [];

          data.rowsData.forEach((row, index) => {
            arrRows.push(
              <SetAgreementRow
                index={index}
                key={index}
                dataAgreement={row}
                handlerRemoveRow={handlerRemoveRow}
              />
            );
          });

          setCurrentSetAgreements(arrRows);
        }
      } catch (err) {
        console.log(err);
      }

      handleShowModal();
    }
  };

  const getAgreements = async () => {
    try {
      const { data } = await axios.post(
        `${config.SERVER_IP}/sets/getAgreements`,
        {},
        {
          withCredentials: true,
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
          },
          params: {},
        }
      );

      setAgreements(data.rowsData);
    } catch (err) {
      console.log(err);
    }
  };

  const handlerRemoveRow = (index) => {
    setCurrentSetAgreements((prev) => {
      let copy = [...prev];
      copy = copy.filter((item, i) => parseInt(item.key) !== index);
      return copy;
    });
  };

  const handlerAddAgreementRow = (dataAgreement) => {
    setCurrentSetAgreements((prevState) => {
      return [
        ...prevState,
        <SetAgreementRow
          index={prevState.length}
          key={prevState.length}
          dataAgreement={dataAgreement}
          handlerRemoveRow={handlerRemoveRow}
        />,
      ];
    });
  };

  const notifySaveSuccess = () => {
    toast.success('נשמר בהצליה', {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  };

  const notifyDeleteSuccess = () => {
    toast.success('נמחק בהצליה', {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  };

  const notifyErrorChoeseAgreements = () => {
    toast.error('נא לבחור הסכם לסט קודם', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  };

  const notifyError = () => {
    toast.error('נא לבחור שם לסט קודם', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  };

  /* Triggers */

  useEffect(() => {
    getSetsTable();
  }, []);

  useEffect(() => {
    getAgreements();
  }, []);

  return (
    <div className='page sets'>
      <TopLine title={data.name} />

      <div className='sub-top-line'>
        <button className='add-set' onClick={handleShowModal}>
          <img src={setIcon} alt='' />
          <span>הוספת סט</span>
        </button>
      </div>

      <div className='content'>
        <TableSets rows={rows} handlerRowClick={handlerRowClick} />
      </div>

      {showModal && (
        // ReactDOM.createPortal(
        <ModalSet
          setShowModal={setShowModal}
          agreements={agreements}
          setAgreements={setAgreements}
          currentSetAgreements={currentSetAgreements}
          setCurrentSetAgreements={setCurrentSetAgreements}
          currentSetId={currentSetId}
          setCurrentSetId={setCurrentSetId}
          handlerAddAgreementRow={handlerAddAgreementRow}
          getSetsTable={getSetsTable}
          notifyError={notifyError}
          notifySaveSuccess={notifySaveSuccess}
          notifyErrorChoeseAgreements={notifyErrorChoeseAgreements}
          notifyDeleteSuccess={notifyDeleteSuccess}
        />
      )}
      <ToastContainer />
    </div>
  );
}
