import './AgentSumRow.scss';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { numberWithCommas } from '../../pages/utils/helpers';
import { config } from '../../Constants';

export default function AgentSumRow({
  deviation,
  arrayShown,
  groupRowsIdentifier,
  indexRow,
  rowAgreements,
  totalSales,
  totalCancellations,
  totalAgentFee,
  totalTalpiotFee,
  totalAgentCompletion,
  currentAgentFullName,
  currentYear,
  currentAgentNumber,
  currentCompany,
  currentTopic,
  currentSubTopic,
  handlerSumClick,
  agreement,
  agreementAgreementReward,
}) {
  /* Variables */
  const [agentRewardArray, setAgentRewardArray] = useState(null);
  const [talpiotRewardArray, setTalpiotRewardArray] = useState(null);
  const [agentCompletionRewardArray, setAgentCompletionRewardArray] = useState(
    null
  );

  const [gainAgent, setGainAgent] = useState(-1);
  const [gainTalpiot, setGainTalpiot] = useState(-1);
  const [gainAgentCompletion, setGainAgentCompletion] = useState(-1);
  const [actualTotal, setActualTotal] = useState(0);

  /* Functions */

  //   const getAgreementRewards = async (agreement) => {
  //     try {
  //       const { data } = await axios.post(
  //         `${config.SERVER_IP}/agreements/getAgreementReward`,
  //         {
  //           withCredentials: true,
  //           mode: 'cors',
  //           credentials: 'include',
  //           headers: {
  //             'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
  //           },
  //         },
  //         { params: { id_agreement: agreement.id_agreement } }
  //       );
  //       if (data && data.rowsData) {
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  useEffect(() => {
    if (parseInt(agreementAgreementReward[0].reward_type) === 0) {
      setAgentRewardArray(agreementAgreementReward);
    }
    if (parseInt(agreementAgreementReward[0].reward_type) === 1) {
      setAgentCompletionRewardArray(agreementAgreementReward);
    }
    if (parseInt(agreementAgreementReward[0].reward_type) === 2) {
      setTalpiotRewardArray(agreementAgreementReward);
    }
  }, [agreementAgreementReward]);

  const calculateRewardForType0 = (firstRow) => {
    const goal = parseInt(firstRow.goal);
    const reward = parseInt(firstRow.reward);
    const numToMultiply = Math.floor(actualTotal / goal);

    return numToMultiply * reward;
  };

  const calculateRewardForType1 = (rewardArray) => {
    for (let i = rewardArray.length - 1; i >= 0; i--) {
      const row = rewardArray[i];
      const goal = parseInt(row.goal);

      if (actualTotal >= goal) {
        // reward_method -> fixed amount
        let reward = parseInt(row.reward);

        // reward_method -> percentage (of total)
        if (parseInt(row.reward_method) === 1) {
          reward = (reward / 100) * actualTotal;
        }

        return reward;
      }
    }

    return 0;
  };

  const calculateRewardForType2 = (rewardArray) => {
    let totalReward = 0;

    for (let i = rewardArray.length - 1; i >= 0; i--) {
      const row = rewardArray[i];
      const goal = parseInt(row.goal);

      if (actualTotal >= goal) {
        // reward_method -> fixed amount
        let reward = parseInt(row.reward);

        // reward_method -> percentage (of total)
        if (parseInt(row.reward_method) === 1) {
          reward = (reward / 100) * actualTotal;
        }

        totalReward += reward;
      }
    }

    return totalReward;
  };

  const calculateAgentReward = () => {
    if (agentRewardArray.length > 0) {
      const type = parseInt(agentRewardArray[0].reward_type);

      // accumulative
      if (type === 0) {
        setGainAgent(calculateRewardForType0(agentRewardArray[0]));
      }

      // highest step
      else if (type === 1) {
        setGainAgent(calculateRewardForType1(agentRewardArray));
      }

      // Every step by itself
      else if (type === 2) {
        setGainAgent(calculateRewardForType2(agentRewardArray));
      }
    }
  };

  const calculateTalpiotReward = () => {
    if (talpiotRewardArray.length > 0) {
      const type = parseInt(talpiotRewardArray[0].reward_type);

      // accumulative
      if (type === 0) {
        setGainTalpiot(calculateRewardForType0(talpiotRewardArray[0]));
      }

      // highest step
      else if (type === 1) {
        setGainTalpiot(calculateRewardForType1(talpiotRewardArray));
      } else if (type === 2) {
        setGainTalpiot(calculateRewardForType2(talpiotRewardArray));
      }
    }
  };

  const calculateAgentCompletionReward = () => {
    if (agentCompletionRewardArray.length > 0) {
      const type = parseInt(agentCompletionRewardArray[0].reward_type);

      // accumulative
      if (type === 0) {
        setGainAgentCompletion(
          calculateRewardForType0(agentCompletionRewardArray[0])
        );
      }

      // highest step
      else if (type === 1) {
        setGainAgentCompletion(
          calculateRewardForType1(agentCompletionRewardArray)
        );
      } else if (type === 2) {
        setGainAgentCompletion(
          calculateRewardForType2(agentCompletionRewardArray)
        );
      }
    }
  };

  const validateDeviationGap = (calculatedSum, sumFromFile) => {
    const minusDeviation = parseInt(deviation) * -1;
    const subtraction = calculatedSum - sumFromFile;

    return subtraction >= minusDeviation && subtraction <= parseInt(deviation);
  };

  /* Triggers */

  //   useEffect(() => {
  //     const fetchAgreements = async () => {
  //       const promises = rowAgreements.map(async (agreement) => {
  //         if (agreement.id_agreement) {
  //           return getAgreementRewards(agreement);
  //         }
  //       });

  //       const results = await Promise.all(promises);

  //       // Process the results if needed
  //     };

  //     if (rowAgreements.length > 0) {
  //       fetchAgreements();
  //     } else {
  //       setAgentRewardArray(null);
  //       setTalpiotRewardArray(null);
  //       setAgentCompletionRewardArray(null);
  //     }
  //   }, [rowAgreements]);

  // calculate actual total (subtracting cancellations)
  useEffect(() => {
    if (totalSales && totalCancellations) {
      setActualTotal(totalSales + totalCancellations);
    }
  }, [totalSales, totalCancellations]);

  // calculate Agent Reward
  useEffect(() => {
    if (actualTotal && agentRewardArray) {
      calculateAgentReward();
    } else {
      setGainAgent(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualTotal, agentRewardArray]);

  // calculate Talpiot Reward
  useEffect(() => {
    if (actualTotal && talpiotRewardArray) {
      calculateTalpiotReward();
    } else {
      setGainTalpiot(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualTotal, talpiotRewardArray]);

  // calculate Agent Completion Reward
  useEffect(() => {
    if (actualTotal && agentCompletionRewardArray) {
      calculateAgentCompletionReward();
    } else {
      setGainAgentCompletion(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualTotal, agentCompletionRewardArray, gainAgent]);

  return (
    <div
      className={`row sum ${
        arrayShown.includes(groupRowsIdentifier) ? 'shown' : ''
      }`}
      key={indexRow + 'sum'}
      data-identify={groupRowsIdentifier}
      onClick={handlerSumClick}
    >
      <div className='cell'> {currentAgentFullName} </div>
      <div className='cell'> {currentAgentNumber} </div>
      {/* <div className='cell'> </div> */}
      <div className='cell'> {currentCompany} </div>
      {/* <div className='cell'> </div> */}
      {/* <div className='cell'> {currentTopic} </div> */}
      <div className='cell'> </div>
      {/* <div className='cell'> {currentSubTopic} </div> */}
      <div className='cell'> </div>
      <div className='cell'> {numberWithCommas(totalSales)} </div>
      <div className='cell'> {numberWithCommas(totalCancellations)} </div>

      <div className='cell'>
        {' '}
        {numberWithCommas(totalAgentFee)}
        {gainAgent >= 0 && (
          <span
            className={`gain ${
              validateDeviationGap(gainAgent, parseInt(totalAgentFee))
                ? 'green'
                : 'red'
            }`}
          >
            ({numberWithCommas(gainAgent)})
          </span>
        )}
      </div>

      <div className='cell'>
        {numberWithCommas(totalTalpiotFee)}{' '}
        {gainTalpiot >= 0 && (
          <span
            className={`gain ${
              validateDeviationGap(gainTalpiot, parseInt(totalTalpiotFee))
                ? 'green'
                : 'red'
            }`}
          >
            ({numberWithCommas(gainTalpiot)})
          </span>
        )}
      </div>

      <div className='cell'>
        {numberWithCommas(totalAgentCompletion)}{' '}
        {gainAgentCompletion >= 0 && (
          <span
            className={`gain ${
              validateDeviationGap(
                gainAgentCompletion,
                parseInt(totalAgentCompletion)
              )
                ? 'green'
                : 'red'
            }`}
          >
            ({numberWithCommas(gainAgentCompletion)})
          </span>
        )}
      </div>
      <div className='cell'> {currentYear} </div>
    </div>
  );
}
