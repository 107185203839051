import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import { config } from '../Constants';

export const AuthContext = createContext({
  isLogged: false,
  setIsLogged: (isLogged) => {},
  isSideBarOpen: false,
  setIsSideBarOpen: (isSideBarOpen) => {},
});

const AuthContextProvider = (props) => {
  const [isLogged, setIsLogged] = useState(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    const verifyUser = async () => {
      try {
        const { data } = await axios.get(
          `${config.SERVER_IP}/login/verifyUser`,
          {
            withCredentials: true,
            mode: 'cors',
            credentials: 'include',
            headers: {
              'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
            },
          }
        );

        if (data && data.username) {
          setIsLogged(true);
          setUser(data);
        } else {
          setUser(null);
          setIsLogged(false);
        }
      } catch (err) {
        console.log(err);
      }
    };
    verifyUser();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLogged,
        setIsLogged,
        isSideBarOpen,
        setIsSideBarOpen,
        user,
        setUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
