import './TableFlow.scss';
import x_icon from '../../assets/icons/x.svg';
import React, {useEffect} from 'react';
import {numberWithCommas} from '../../pages/utils/helpers';

function TableFlow({flowRows, setFlowRows, setSum, numRowForm}) {
	/* Functions */

	useEffect(() => {
		if (flowRows) {
			let incr = 0;

			flowRows.forEach((row) => {
				incr += parseInt(row.amount);
			});

			setSum(numberWithCommas(incr));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [flowRows]);

	const handlerRemoveCellFlow = (e) => {
		setFlowRows((prevState) => {
			return prevState.filter(function (person, i) {
				return i !== parseInt(e.target.getAttribute('data-num'));
			});
		});
	};

	return (
		<div className="table flow-table">
			<div className="row head" key={'head'}>
				<div className="cell cell-remove"></div>
				<div className="cell">תאריך</div>
				<div className="cell">סכום</div>
				<div className="cell">הסבר</div>
			</div>

			{flowRows &&
				flowRows.map((row, indexRow) => (
					<div className="row" key={indexRow}>
						<div className="cell cell-remove">
							<img data-num={indexRow} src={x_icon} alt="delete" onClick={handlerRemoveCellFlow} />
						</div>
						<div className="cell" id={`cell-flow-date${indexRow}`}>
							{row.line_date}
						</div>
						<div className="cell" id={`cell-flow-amount${indexRow}`}>
							{row.amount}
						</div>
						<div className="cell" id={`cell-flow-comment${indexRow}`}>
							{row.comment}
						</div>
					</div>
				))}

			{numRowForm.items}
		</div>
	);
}

export default TableFlow;
