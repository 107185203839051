import {numberWithCommas} from '../../pages/utils/helpers';
import React from 'react';
import './TableCompanies.scss';

function TableCompanies({rows}) {
	/* Functions */

	return (
		<div className="table companies-table">
			<div className="row head" key={'head'}>
				<div className="cell" key={'head1'}>
					חברה
				</div>
				<div className="cell" key={'head2'}>
					תחום
				</div>
				<div className="cell" key={'head3'}>
					סוג
				</div>
				<div className="cell" key={'head4'}>
					סכום מכירות כולל
				</div>
				<div className="cell" key={'head5'}>
					ביטולים
				</div>
				<div className="cell" key={'head6'}>
					ע.הקפים סוכנים
				</div>
				<div className="cell" key={'head7'}>
					ע. הקפים תלפיות
				</div>
				<div className="cell" key={'head8'}>
					השלמת סוכנים
				</div>
				<div className="cell" key={'head9'}>
					מרווח
				</div>
			</div>

			{rows &&
				rows.map((row, indexRow) => {
					return (
						<div className="row" key={indexRow}>
							{Object.values(row).map((cell, indexCell) => (
								<div className="cell" key={indexRow + '_' + indexCell}>
									{numberWithCommas(cell)}
								</div>
							))}

							<div className="cell" key={indexRow + '_9'}>
								{numberWithCommas(row.talpiotFee - row.agentCompletion)}
							</div>
						</div>
					);
				})}
		</div>
	);
}

export default TableCompanies;
