import React, { useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import { config } from '../../Constants';
import './Reset.scss';

const Reset = () => {
  /* Variables */
  const [searchParams] = useSearchParams();
  const inputPassword = useRef();
  const inputPasswordAgain = useRef();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  /* Functions */
  const handlerSubmit = async () => {
    setErrorMessage('');

    const secretKey = searchParams.get('key');
    const password = inputPassword.current.value;
    const passwordAgain = inputPasswordAgain.current.value;

    if (secretKey) {
      if (!password || !passwordAgain) {
        setErrorMessage('יש למלא את שני השדות');
      } else if (password !== passwordAgain) {
        setErrorMessage('הסיסמאות אינן זהות');
      } else {
        try {
          const result = await axios.post(
            `${config.SERVER_IP}/login/resetPassword`,
            {},
            {
              withCredentials: true,
              mode: 'cors',
              credentials: 'include',
              headers: {
                'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
              },
              params: { password: password, secretKey: secretKey },
            }
          );

          if (result.data && result.data === 'success') {
            navigate('/login');
          } else {
            setErrorMessage('אירעה תקלה');
          }
        } catch (err) {
          console.log(err);
        }
      }
    } else {
      setErrorMessage('אירעה תקלה');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handlerSubmit();
    }
  };

  return (
    <div className='page reset'>
      <form>
        <div className='logo'>
          <img src={logo} alt='logo' />
        </div>

        <h3>איפוס סיסמה</h3>

        <div className='field'>
          <label htmlFor='password'>סיסמה</label>
          <input
            ref={inputPassword}
            type='password'
            name='password'
            id='password'
            placeholder='סיסמה'
            onKeyDown={handleKeyPress}
          />
        </div>

        <div className='field'>
          <label htmlFor='password-again'>סיסמה בשנית</label>
          <input
            ref={inputPasswordAgain}
            type='password'
            name='password-again'
            id='password-again'
            placeholder='סיסמה בשנית'
            onKeyDown={handleKeyPress}
          />
        </div>

        <button type='button' className='submit' onClick={handlerSubmit}>
          שמירה
        </button>

        {errorMessage.length > 1 && (
          <p className='error-message'>{errorMessage}</p>
        )}
      </form>
    </div>
  );
};

export default Reset;
