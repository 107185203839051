import React, { useRef, useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import { config } from '../../Constants';
import './Login.scss';

const Login = () => {
  /* Variables */
  const inputUsername = useRef();
  const inputPassword = useRef();
  const { setIsLogged, isLogged, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  /* Functions */
  const handlerSubmit = async () => {
    setErrorMessage('');

    const username = inputUsername.current.value;
    const password = inputPassword.current.value;

    try {
      const { data } = await axios.post(
        `${config.SERVER_IP}/login/checkLogin`,
        { username: username, password: password }, // This is the request body
        {
          withCredentials: true,
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
          },
        }
      );
      if ((data && data.user_id) || (data && data.success)) {
        setIsLogged(true);
        if (data.user_id) {
          setUser(data);
        } else {
          setUser(data.data);
        }
        navigate('/');
      } else {
        setErrorMessage(data.message);
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        // Handle unauthorized error here
        setErrorMessage('Unauthorized access. Please check your credentials.');
      } else {
        console.log(err);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handlerSubmit();
    }
  };

  // const resetHandler = async () => {
  //   try {
  //     const result = await axios.post(`${config.SERVER_IP}/login/sendResetEmail`, {
  //       withCredentials: true, mode: 'cors', credentials: 'include', headers: {
  //         'Access-Control-Allow-Origin': config.ALLOW_ORIGIN
  //       }
  //     }, { params: {} });
  //
  //     console.log("result ", result);
  //     if (result.data && result.data === "success") {
  //       setErrorMessage("נשלח קישור לאיפוס הסיסמא לאימייל הרשום במערכת")
  //     }
  //
  //     else {
  //       setErrorMessage("אירעה תקלה")
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  useEffect(() => {
    if (isLogged) navigate('/');
  }, [isLogged]);

  return (
    <div className='page login'>
      <form>
        <div className='logo'>
          <img src={logo} alt='logo' />
        </div>

        <div className='field'>
          <label htmlFor='username'>שם משתמש</label>
          <input
            ref={inputUsername}
            type='text'
            name='username'
            id='username'
            placeholder='שם משתמש'
            onKeyDown={handleKeyPress}
          />
        </div>

        <div className='field'>
          <label htmlFor='password'>סיסמה</label>
          <input
            ref={inputPassword}
            type='password'
            name='password'
            id='password'
            placeholder='סיסמה'
            onKeyDown={handleKeyPress}
          />
        </div>

        <button type='button' className='submit' onClick={handlerSubmit}>
          כניסת למערכת
        </button>

        {/*<button className="reset" type="button" onClick={resetHandler}>איפוס סיסמה</button>*/}

        {errorMessage.length > 1 && (
          <p className='error-message'>{errorMessage}</p>
        )}
      </form>
    </div>
  );
};

export default Login;
