import x_icon from '../../assets/icons/x.svg';
import React from 'react';
import './SetAgreementRow.scss';
import {getAgreementTypeName} from '../../pages/utils/helpers';

export default function SetAgreementRow({index, handlerRemoveRow, dataAgreement}) {
	return (
		<div className="row set-agreement-row" key={index}>
			{dataAgreement && dataAgreement.name && (
				<>
					<div
						className="cell cell-remove"
						onClick={() => {
							handlerRemoveRow(index);
						}}
					>
						<img data-num={index} src={x_icon} alt="delete" />
					</div>
					<div className="cell">{dataAgreement.name}</div>
					<div className="cell">{dataAgreement.company}</div>
					<div className="cell">{dataAgreement.topic}</div>
					<div className="cell">{dataAgreement.subtopic}</div>
					<div className="cell">{dataAgreement.start}</div>
					<div className="cell">{dataAgreement.end}</div>
					<div className="cell">{getAgreementTypeName(dataAgreement.type)}</div>
				</>
			)}
		</div>
	);
}
