import React, {useEffect, useRef, useState} from 'react';
import './AgreementGoalRow.scss';

export default function AgreementGoalRow({
	index,
	goalIndex,
	handlerRemove,
	goal_number = '',
	reward_number = '',
	reward_method = 0,
}) {
	/* Variables */

	const [goalNumber, setGoalNumber] = useState(goal_number);
	const [rewardNumber, setRewardNumber] = useState(reward_number);
	const [rewardMethod, setRewardMethod] = useState(reward_method);
	const goalNumberInput = useRef();
	const rewardNumberInput = useRef();

	/* Functions */
	const handlerGoalNumber = (index) => {
		setGoalNumber(goalNumberInput.current.value);
	};

	const handlerRewardNumber = () => {
		setRewardNumber(rewardNumberInput.current.value);
	};

	const handlerRewardMethod = () => {
		setRewardMethod((perv) => {
			if (perv === 0) {
				if (parseFloat(rewardNumberInput.current.value) > 100) {
					rewardNumberInput.current.value = '';
				}

				return 1;
			}

			return 0;
		});
	};

	useEffect(() => {
		setGoalNumber(goal_number);
	}, [goal_number]);

	useEffect(() => {
		setRewardNumber(reward_number);
	}, [reward_number]);

	useEffect(() => {
		setRewardMethod(reward_method);
	}, [reward_method]);

	return (
		<div
			className={`row agreement-goal-row ${index === 0 ? 'first' : ''}`}
			data-goal-number={goalNumber}
			data-reward-number={rewardNumber}
			data-reward-method={rewardMethod}
		>
			<div className="cell">
				{index === 0 && <label htmlFor="goal-number">יעד</label>}
				<input
					className="goal-number"
					ref={goalNumberInput}
					onInput={handlerGoalNumber}
					type="number"
					min="1"
					defaultValue={goalNumber ? goalNumber : goal_number}
					name="agent-number"
					placeholder="יעד"
				/>
			</div>

			<div className="cell">
				{index === 0 && <label htmlFor="reward-number">תקבול</label>}
				<input
					className="reward-number"
					ref={rewardNumberInput}
					onInput={handlerRewardNumber}
					type="number"
					defaultValue={rewardNumber ? rewardNumber : reward_number}
					name="agent-number"
					placeholder="תקבול"
					min="1"
					max={rewardMethod === 0 ? '10000000' : '100'}
				/>
			</div>

			{goalIndex !== 0 && (
				<div className="cell method" onClick={handlerRewardMethod}>
					<span>{rewardMethod === 0 ? '₪' : '%'}</span>
				</div>
			)}

			{goalIndex !== 0 && (
				<div
					className="cell remove"
					onClick={() => {
						handlerRemove(index);
					}}
				>
					<span>x</span>
				</div>
			)}
		</div>
	);
}
