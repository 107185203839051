import './Companies.scss';
import TopLine from '../../components/TopLine/TopLine';
import MyDrop from '../../components/MyDrop/MyDrop';
import React, { useEffect, useState } from 'react';
import excel from '../../assets/icons/excel.svg';
import writeXlsxFile from 'write-excel-file';
import axios from 'axios';
import TableCompanies from '../../components/TableCompanies/TableCompanies';
import 'react-dropdown/style.css';
import { config } from '../../Constants';
// import {AbortController} from 'abort-controller';

export default function Companies({ data }) {
  /* Variables */
  const [rows, setRows] = useState([]);

  const [year, setYear] = useState('');
  const [yearOptions, setYearOptions] = useState([]);
  const [resertAgentDropDown, setResetAgentDropDown] = useState(false);

  /* Functions */
  const getCompaniesTable = async (yearParam) => {
    const paramsObj = { year: year };

    try {
      const { data } = await axios.post(
        `${config.SERVER_IP}/companies/getCompaniesTable`,
        {},
        {
          withCredentials: true,
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
          },
          params: paramsObj,
        }
      );

      setRows(data.rowsData);
    } catch (err) {
      console.log(err);
    }
  };

  const getYears = async (controller) => {
    try {
      const resp = await axios.post(
        `${config.SERVER_IP}/companies/getYears`,
        {},
        {
          signal: controller.signal,
          withCredentials: true,
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
          },
          params: {},
        }
      );
      const data = resp.data;

      if (data.rowsData) {
        const arrayYears = [];

        for (let row of data.rowsData) {
          arrayYears.push(row.year);
        }

        setYear(arrayYears[0]);
        setYearOptions(arrayYears);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const yearHandler = (index) => {
    setYear(yearOptions[index]);
  };

  const handlerExport = async () => {
    const tableRows = document.querySelectorAll('.companies-table .row');
    const arrayToExport = [];

    tableRows.forEach((row) => {
      const rowCells = row.querySelectorAll('.cell');
      const rowArray = [];

      rowCells.forEach((cell) => {
        rowArray.push({
          value: cell.innerText,
          width: 13,
          height: 25,
          align: 'center',
          fontWeight: row.classList.contains('head') ? 'bold' : '400',
        });
      });

      arrayToExport.push(rowArray);
    });

    const columns = [
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
    ];

    await writeXlsxFile(arrayToExport, {
      columns: columns,
      fileName: 'טבלת סיכום חברות.xlsx',
    });
  };

  /* Triggers */

  useEffect(() => {
    let controller = new AbortController();
    getYears(controller);

    return () => {
      if (controller) {
        controller.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (year) {
      getCompaniesTable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  return (
    <div className='page agent'>
      <TopLine title={data.name} />

      <div className='sub-top-line'>
        <MyDrop
          className='year'
          optionsArr={yearOptions}
          initValueIndex={0}
          handler={yearHandler}
          setToFirst={setResetAgentDropDown}
        />

        <button className='export-file' onClick={handlerExport}>
          <img src={excel} width='20' alt='excel' />
          <span>יצוא טבלת אקסל</span>
        </button>
      </div>

      <div className='content'>
        <TableCompanies rows={rows} />
      </div>
    </div>
  );
}
