import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import AgreementGoalRow from '../../components/AgreementGoalRow/AgreementGoalRow';
import MyDrop from '../../components/MyDrop/MyDrop';
import rightArrow from '../../assets/icons/right-arrow.svg';
import plus2 from '../../assets/icons/plus2.svg';
import minus from '../../assets/icons/minus.svg';
import save from '../../assets/icons/save.svg';
import { config } from '../../Constants';
import './ModalNewAgreement.scss';

export default function ModalNewAgreement({
  setCurrentAgreement,
  setShowModal,
  getAgreementsTable,
  currentAgreement,
}) {
  /* Functions */

  const handleHideModal = () => {
    setCurrentAgreement(null);
    setShowModal(false);
  };

  const handlerSubmit = async () => {
    let flagValidate = true;

    const name = nameRef.current.value;
    const company = companyOptions[companyIndex];
    const topic = topicOptions[topicIndex];
    const subtopic = subtopicOptions[subtopicIndex];
    const start = startRef.current.value;
    const end = endRef.current.value;
    const filled = isFilledIndex;

    nameRef.current.classList.remove('error');
    startRef.current.classList.remove('error');
    endRef.current.classList.remove('error');

    if (!name) {
      nameRef.current.classList.add('error');
      flagValidate = false;
    }

    if (!start) {
      startRef.current.classList.add('error');
      flagValidate = false;
    }

    if (!end) {
      endRef.current.classList.add('error');
      flagValidate = false;
    }

    const cancellations = [];
    document
      .querySelectorAll('.container-cancellations-percentages input')
      .forEach((element, i) => {
        cancellations.push([idAgreement, i + 1, element.value]);
      });

    const rewards = [];
    document.querySelectorAll('.agreement-goal-row').forEach((element) => {
      const goal_number = parseFloat(element.getAttribute('data-goal-number'));
      const reward_number = parseFloat(
        element.getAttribute('data-reward-number')
      );

      if (!goal_number || goal_number <= 0) {
        flagValidate = false;

        element.querySelector('.goal-number').classList.add('error');
      } else {
        element.querySelector('.goal-number').classList.remove('error');
      }

      if (!reward_number || reward_number <= 0) {
        flagValidate = false;

        element.querySelector('.reward-number').classList.add('error');
      } else {
        element.querySelector('.reward-number').classList.remove('error');
      }

      const reward_method =
        goalIndex === 0
          ? 0
          : parseInt(element.getAttribute('data-reward-method'));

      rewards.push([
        idAgreement,
        goalIndex,
        goal_number,
        reward_number,
        reward_method,
      ]);
    });

    const params = {
      id_agreement: idAgreement,
      name: name,
      company: company,
      topic: topic,
      subtopic: subtopic,
      start: start,
      end: end,
      filled: filled,
      type: typeIndex,
      cancellations: cancellations,
      rewards: rewards,
    };

    if (flagValidate) {
      try {
        const { data } = await axios.post(
          `${config.SERVER_IP}/agreements/insertAgreement`,
          {},
          {
            withCredentials: true,
            mode: 'cors',
            credentials: 'include',
            headers: {
              'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
            },
            params: params,
          }
        );

        if (data.result && data.result === 'success') {
          setCurrentAgreement(null);
          setShowModal(false);
          getAgreementsTable();
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handlerDelete = async () => {
    if (confirmDelete) {
      const params = {
        id_agreement: idAgreement,
      };

      try {
        const { data } = await axios.post(
          `${config.SERVER_IP}/agreements/deleteAgreement`,
          {},
          {
            withCredentials: true,
            mode: 'cors',
            credentials: 'include',
            headers: {
              'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
            },
            params: params,
          }
        );

        if (data.result && data.result === 'success') {
          setCurrentAgreement(null);
          setShowModal(false);
          getAgreementsTable();

          setConfirmDelete(false);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      setConfirmDelete(true);
    }
  };

  const handlerAddGoalRow = () => {
    setAgreementGoalRows((prevState) => {
      return [
        ...prevState,
        <AgreementGoalRow
          key={prevState.length}
          index={prevState.length}
          goalIndex={goalIndex}
          handlerRemove={handlerRemoveGoalRow}
        />,
      ];
    });
  };

  const handlerRemoveGoalRow = (index) => {
    setAgreementGoalRows((prev) => {
      let copy = [...prev];
      copy = copy.filter((item, i) => parseInt(item.key) !== index);
      return copy;
    });
  };

  const getCompaniesOptions = async () => {
    try {
      const { data } = await axios.post(
        `${config.SERVER_IP}/settings/getCompanies`,
        {},
        {
          withCredentials: true,
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
          },
          params: {},
        }
      );

      if (data && data.rowsData) {
        const arr = [];

        data.rowsData.forEach((item, index) => {
          arr.push(item.name);
        });

        setCompanyOptions(arr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getTopicsOptions = async () => {
    try {
      const { data } = await axios.post(
        `${config.SERVER_IP}/settings/getTopics`,
        {},
        {
          withCredentials: true,
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
          },
          params: {},
        }
      );

      if (data && data.rowsData) {
        const arr = [];

        data.rowsData.forEach((item, index) => {
          arr.push(item.name);
        });

        setTopicOptions(arr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSubtopicsOptions = async () => {
    try {
      const { data } = await axios.post(
        `${config.SERVER_IP}/settings/getSubtopics`,
        {},
        {
          withCredentials: true,
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
          },
          params: {},
        }
      );

      if (data && data.rowsData) {
        const arr = [];

        data.rowsData.forEach((item, index) => {
          arr.push(item.name);
        });

        setSubtopicOptions(arr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  /* Variables */
  const nameRef = useRef();
  const startRef = useRef();
  const endRef = useRef();

  const [idAgreement, setIdAgreement] = useState(new Date().getTime());

  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyIndex, setCompanyIndex] = useState(0);

  const [topicOptions, setTopicOptions] = useState([]);
  const [topicIndex, setTopicIndex] = useState(0);

  const [subtopicOptions, setSubtopicOptions] = useState([]);
  const [subtopicIndex, setSubtopicIndex] = useState(0);

  const filledOptions = ['לא מוזן', 'מוזן'];
  const [isFilledIndex, setIsFilledIndex] = useState(0);

  const typeOptions = ['סוכן-חברה', 'סוכן-תלפיות', 'תלפיות-חברה'];
  const [typeIndex, setTypeIndex] = useState(0);

  const goalOptions = ['יעד מצטבר', 'מדרגה גבוהה ביותר', 'כל מדרגה בנפרד'];
  const [goalIndex, setGoalIndex] = useState(0);

  const [cancellationsYearsValues, setCancellationsYearsValues] = useState([]);

  const [agreementGoalRows, setAgreementGoalRows] = useState([
    <AgreementGoalRow
      key={0}
      index={0}
      goalIndex={goalIndex}
      handlerRemove={handlerRemoveGoalRow}
    />,
  ]);

  const [confirmDelete, setConfirmDelete] = useState(false);

  /* Triggers */

  useEffect(() => {
    getCompaniesOptions();
    getTopicsOptions();
    getSubtopicsOptions();
  }, []);

  useEffect(() => {
    if (
      currentAgreement &&
      companyOptions.length > 0 &&
      topicOptions.length > 0 &&
      subtopicOptions.length > 0
    ) {
      nameRef.current.value = currentAgreement.name;
      startRef.current.value = currentAgreement.start;
      endRef.current.value = currentAgreement.end;
      setIdAgreement(parseInt(currentAgreement.id_agreement));
      setCompanyIndex(companyOptions.indexOf(currentAgreement.company));
      setTopicIndex(topicOptions.indexOf(currentAgreement.topic));
      setSubtopicIndex(subtopicOptions.indexOf(currentAgreement.subtopic));
      setIsFilledIndex(parseInt(currentAgreement.filled));
      setTypeIndex(parseInt(currentAgreement.type));

      const arrCancellations = [];
      currentAgreement.rowsCancellations.forEach((item) => {
        arrCancellations.push(item.percentage);
      });
      setCancellationsYearsValues(arrCancellations);

      // set the reward type
      if (
        currentAgreement.rowsRewards &&
        currentAgreement.rowsRewards.length > 0
      ) {
        setGoalIndex(parseInt(currentAgreement.rowsRewards[0].reward_type));

        const arrRewards = [];
        currentAgreement.rowsRewards.forEach((item, index) => {
          arrRewards.push(
            <AgreementGoalRow
              goalIndex={goalIndex}
              goal_number={item.goal}
              reward_number={item.reward}
              reward_method={item.reward_method}
              key={index}
              index={index}
              handlerRemove={handlerRemoveGoalRow}
            />
          );
        });
        setAgreementGoalRows(arrRewards);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentAgreement,
    companyOptions.length,
    topicOptions.length,
    subtopicOptions.length,
  ]);

  useEffect(() => {
    setAgreementGoalRows((prev) => {
      let arr = [];

      // accumulative reward - keep only the first line
      if (goalIndex === 0) {
        const item = prev[0];
        const index = 0;
        arr.push(
          <AgreementGoalRow
            goalIndex={goalIndex}
            goal_number={item.props.goal_number}
            reward_number={item.props.reward_number}
            reward_method={item.props.reward_method}
            key={index}
            index={index}
            handlerRemove={handlerRemoveGoalRow}
          />
        );
      } else {
        prev.forEach((item, index) => {
          arr.push(
            <AgreementGoalRow
              goalIndex={goalIndex}
              goal_number={item.props.goal_number}
              reward_number={item.props.reward_number}
              reward_method={item.props.reward_method}
              key={index}
              index={index}
              handlerRemove={handlerRemoveGoalRow}
            />
          );
        });
      }

      return arr;
    });
  }, [goalIndex]);

  return (
    <div className='modal-bg'>
      <div className='modal-new-agreement'>
        <div className='inner'>
          <button className='back' onClick={handleHideModal}>
            <img src={rightArrow} alt='' />
            <span>חזרה לדף הסכמים</span>
          </button>

          <div className='container-halves'>
            <div className='half1'>
              <h2>הגדרת הסכם</h2>

              <div className='row'>
                <div className='cell'>
                  <label htmlFor='name'>שם הסכם</label>
                  <input
                    ref={nameRef}
                    type='text'
                    name='name'
                    id='name'
                    placeholder='שם הסכם'
                  />
                </div>

                <div className='cell'>
                  <label htmlFor='company'>חברה</label>
                  <MyDrop
                    className='company'
                    optionsArr={companyOptions}
                    initValueIndex={companyIndex}
                    handler={setCompanyIndex}
                  />
                </div>

                <div className='cell'>
                  <label htmlFor='topic'>תחום</label>
                  <MyDrop
                    className='topic'
                    optionsArr={topicOptions}
                    initValueIndex={topicIndex}
                    handler={setTopicIndex}
                  />
                </div>

                <div className='cell'>
                  <label htmlFor='subtopic'>סוג</label>
                  <MyDrop
                    className='subtopic'
                    optionsArr={subtopicOptions}
                    initValueIndex={subtopicIndex}
                    handler={setSubtopicIndex}
                  />
                </div>
              </div>

              <div className='row'>
                <div className='cell'>
                  <label htmlFor='start'>פעיל החל מ</label>
                  <input
                    ref={startRef}
                    type='date'
                    name='start'
                    id='start'
                    placeholder='בחרו תאריך'
                  />
                </div>

                <div className='cell'>
                  <label htmlFor='end'>פעיל עד</label>
                  <input
                    ref={endRef}
                    type='date'
                    name='end'
                    id='end'
                    placeholder='בחרו תאריך'
                  />
                </div>

                <div className='cell'>
                  <label htmlFor='status'>סטטוס</label>
                  <MyDrop
                    className='status'
                    optionsArr={filledOptions}
                    colorizeSwitchOptions='true'
                    initValueIndex={isFilledIndex}
                    handler={setIsFilledIndex}
                  />
                </div>

                <div className='cell'>
                  <label htmlFor='type'>סוג הסכם</label>
                  <MyDrop
                    className='type'
                    optionsArr={typeOptions}
                    initValueIndex={typeIndex}
                    handler={setTypeIndex}
                  />
                </div>
              </div>

              <div className='row-cancellations'>
                <div className='col col1'>
                  <span>שנת ביטול</span>

                  <div className='container-cancellations-years'>
                    {cancellationsYearsValues.map((item, index) => {
                      return <p key={index}>{index + 1}</p>;
                    })}
                  </div>
                </div>

                <div className='col col2'>
                  <span>אחוזי ביטול</span>

                  <div className='container-cancellations-percentages'>
                    {cancellationsYearsValues.map((item, index) => {
                      return (
                        <input
                          key={index}
                          defaultValue={item ? item : ''}
                          type='number'
                          name='cancellation-percentage'
                          id='cancellation-percentage'
                          placeholder='0.0%'
                        />
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className='row-plus-minus'>
                <img
                  className='add-cancellation-year'
                  src={plus2}
                  alt='add cancellation year'
                  onClick={() => {
                    setCancellationsYearsValues((prev) => {
                      return [...prev, 0];
                    });
                  }}
                />

                <img
                  className='subtract-cancellation-year'
                  src={minus}
                  alt='subtract cancellation year'
                  onClick={() => {
                    setCancellationsYearsValues((prev) => {
                      const copy = [...prev];
                      copy.pop();

                      return copy;
                    });
                  }}
                />
              </div>

              <div className='row-actions'>
                <button className='delete-button' onClick={handlerDelete}>
                  <span>{confirmDelete ? 'אישור מחיקה' : 'מחיקת הסכם'}</span>
                </button>

                <button className='submit-button' onClick={handlerSubmit}>
                  <img src={save} alt='save' />
                  <span>שמירה</span>
                </button>
              </div>
            </div>

            <div className='half2'>
              <MyDrop
                optionsArr={goalOptions}
                initValueIndex={goalIndex}
                handler={setGoalIndex}
              />

              {agreementGoalRows.map(function (item) {
                return item;
              })}

              {goalIndex !== 0 && (
                <div className='row'>
                  <img
                    className='add'
                    onClick={handlerAddGoalRow}
                    src={plus2}
                    alt='add'
                  />
                </div>
              )}

              <div className='row-actions mobile'>
                <button className='delete-button' onClick={handlerDelete}>
                  <span>{confirmDelete ? 'אישור מחיקה' : 'מחיקת הסכם'}</span>
                </button>

                <button className='submit-button' onClick={handlerSubmit}>
                  <img src={save} alt='save' />
                  <span>שמירה</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
