import './Agreements.scss';
import TopLine from '../../components/TopLine/TopLine';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import TableAgreements from '../../components/TableAgreements/TableAgreements';
import 'react-dropdown/style.css';
import MyDrop from '../../components/MyDrop/MyDrop';
import ModalNewAgreement from '../../components/ModalNewAgreement/ModalNewAgreement';
import newSet from '../../assets/icons/newSet.svg';
import { config } from '../../Constants';

export default function Agreements({ data }) {
  /* Local variables */
  const [rows, setRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentAgreement, setCurrentAgreement] = useState(null);
  const typeOptions = ['סוכן - חברה', 'סוכן - תלפיות', 'תלפיות - חברה'];
  const [typeIndex, setTypeIndex] = useState(0);
  const [resertAgentDropDown, setResetAgentDropDown] = useState(false);

  /* Functions */
  const getAgreementsTable = async () => {
    try {
      const { data } = await axios.post(
        `${config.SERVER_IP}/agreements/getAgreements`,
        {},
        {
          withCredentials: true,
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
          },
          params: { type: typeIndex },
        }
      );

      setRows(data.rowsData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handlerRowClick = async (id_agreement) => {
    try {
      const { data } = await axios.post(
        `${config.SERVER_IP}/agreements/getAgreement`,
        {},
        {
          withCredentials: true,
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': config.ALLOW_ORIGIN,
          },
          params: { id_agreement: id_agreement },
        }
      );

      if (data.rowsData[0]) {
        data.rowsData[0].rowsCancellations = data.rowsCancellations;
        data.rowsData[0].rowsRewards = data.rowsRewards;
        setCurrentAgreement(data.rowsData[0]);
      }
    } catch (err) {
      console.log(err);
    }

    handleShowModal();
  };

  /* Triggers */
  useEffect(() => {
    getAgreementsTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeIndex]);

  return (
    <div className='page agreements'>
      <TopLine title={data.name} />

      <div className='sub-top-line'>
        <div className='wrapper-filter'>
          <label>סוג הסכם</label>
          <MyDrop
            optionsArr={typeOptions}
            initValueIndex={typeIndex}
            handler={setTypeIndex}
            setToFirst={setResetAgentDropDown}
          />
        </div>
        <button className='add-agreement' onClick={handleShowModal}>
          <img src={newSet} alt='' />
          <span>הסכם חדש</span>
        </button>
      </div>

      <div className='content'>
        <TableAgreements rows={rows} handlerRowClick={handlerRowClick} />
      </div>

      {
        showModal && (
          // ReactDOM.createPortal(
          <ModalNewAgreement
            currentAgreement={currentAgreement}
            getAgreementsTable={getAgreementsTable}
            setCurrentAgreement={setCurrentAgreement}
            setShowModal={setShowModal}
          />
        )
        // 	,
        // 	document.getElementById('modal-root')
        // )
      }
    </div>
  );
}
